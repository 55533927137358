let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

var FilterController = (function() {
  let GROUP_CLASS = undefined;
  let ITEM_CLASS = undefined;
  let KEY_PROJECT_FILTER_TYPE = undefined;
  FilterController = class FilterController {
    static initClass() {
  
      GROUP_CLASS = 'group';
      ITEM_CLASS = 'item';
      KEY_PROJECT_FILTER_TYPE = consts('KEY_PROJECT_FILTER_TYPE');
    }

    constructor(widget) {
      this.click = this.click.bind(this);
      this.widget = widget;
    }
  
    click(e) {
      let topNode = e.currentTarget;
      let clickNode = e.target;
      let node = this.getItemNode(clickNode, topNode);
      let selTagExprsPath = `${consts('KEY_PROJECT_TAG_STATES')}${this.getItemKey(node)}`;
      let selTagExprs = this.widget.get(selTagExprsPath);
      if (this.filterType() !== 'radio') {
        this.handleCheckboxClick(node, selTagExprsPath, selTagExprs);
      } else {
        this.handleRadioClick(node, selTagExprsPath, selTagExprs);
      }
      return _.preventDefault(e);
    }

    handleRadioClick(node, selTagExprsPath, selTagExprs) {
      if (!this.isGroupNode(node) && !selTagExprs) {
        let {parentKey, key} = _.splitKey(selTagExprsPath);
        let newValue = {};
        newValue[key] = this.getItemValue(node);
        return this.widget.publish(parentKey, newValue);
      }
    }

    handleCheckboxClick(node, selTagExprsPath, selTagExprs) {
      let newValue;
      if (this.isGroupNode(node)) {
        let checked = _.any(selTagExprs, tagExpr => tagExpr);
        newValue = checked ? undefined : this.getGroupItemExprs(node);
        return this.widget.publish(selTagExprsPath, newValue);
      } else {
        newValue = selTagExprs ? undefined : this.getItemValue(node);
        return this.widget.publish(selTagExprsPath, newValue);
      }
    }

    getItemNode(node, topNode) {
      return _.findParentNode(node, topNode, node => $.nodeName(node) === 'LI');
    }

    getItemValue(node) { return $.dataset(node, 'itemvalue'); }

    getGroupItemExprs(node) {
      let key = $.dataset(node, 'itemkey');
      let groupTag = this.widget.get(`${this.widget.key}${key}`);
      return _.map(groupTag.children, tag => tag.name);
    }

    getItemKey(node) { return $.dataset(node, 'itemkey'); }

    isGroupNode(node) { return $.hasClass(node, GROUP_CLASS); }

    isItemNode(node) { return $.hasClass(node, ITEM_CLASS); }

    class(item) { if (item.children) { return GROUP_CLASS; } else { return ITEM_CLASS; } }

    value(item) { if (item.children) { return null; } else { return item.name; } }

    filterType() { return this.widget.get(KEY_PROJECT_FILTER_TYPE) || 'checkbox'; }

    inputType(item) {
      let type = this.filterType();
      if (item.children && (type === 'radio')) { type = undefined; }
      return type;
    }
  };
  FilterController.initClass();
  return FilterController;
})();

rh.controller('FilterController', FilterController);