let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;
let { model } = rh;

_.getRootUrl = (function() {
  let rootUrl = null;
  return () => rootUrl != null ? rootUrl : (rootUrl = `${_.getHostFolder()}${consts('START_FILEPATH')}`);
})();

_.getDefaultTopic = function (callback) {
  let gDefaultTopic = window.gDefaultTopic && window.gDefaultTopic.substring(1)
  if (gDefaultTopic && gDefaultTopic.endsWith("rhpj"))
  {
    let projPath = `./mergedProjects/${gDefaultTopic.substring(0, gDefaultTopic.length - 5)}`
    let filePath = `${projPath}/whxdata/projectsettings.js`
    _.loadScript(filePath, true, () =>
    {
      gDefaultTopic = _.exports()
      gDefaultTopic = `${projPath}/${gDefaultTopic}`
      window.gDefaultTopic = `#${gDefaultTopic}`
      callback(gDefaultTopic)
    })
  }
  else{ 
    callback(gDefaultTopic)    
  }
}

_.runTopicLoadingAnimation = (function() {
  let runAnimation = null;
  let topicLoading = false;
  return function(flag) {
    topicLoading = flag;
    if (flag) {
      if (runAnimation == null) { runAnimation = _.debounce(() => model.publish(consts('EVT_TOPIC_LOADING'), topicLoading)
      , 700); }
      return runAnimation();
    } else {
      runAnimation = null;
      return model.publish(consts('EVT_TOPIC_LOADING'), false);
    }
  };
})();

_.getTopicFrame = function() {
  let topicFrameName = window.gTopicFrameName || 'rh_default_topic_frame_name';
  return $(`[name=${topicFrameName}]`, 0);
};

_.goToDefaultTopic = function () {
  let newMap = {};
  _.getDefaultTopic(defaultTopic => {
    newMap[consts('HASH_KEY_TOPIC')] = defaultTopic;
    _.updateHashMap(newMap, true)    
  }
  )
  return false
}
