let rh = require("../../../../lib/rh")
const consts = rh.consts;

class Paths{
  constructor(path){
    this._path = path || ""
    this.initPath()
  }
  getPath(){ 
    return this._path
  }
  initPath(){
    if(this._path === "."){
      this._path = ""
    }
    if(this._path[0] === '.' && this._path[1] === '/'){
      this._path = this._path.slice(2)
    }
  }
  _getModelFileName(fileNo){
    let prefix = consts("SEARCH_MODEL_ADDR");
    if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
      prefix = consts("SEARCH_MODEL_ADDR_OLD");
    }
    return  prefix + fileNo.toString() +".js";
  }
  getModelFilePath(fileno){
    let fileName = this._getModelFileName(fileno)
    return this._getRelativePath(fileName)
  }
  _getRelativePath(filepath){
    let path = (this._path === "")? filepath :  this._path + '/' + filepath

    return path
  }
  _getPathKey(){
    return (this._path === "")? "" :  '_' + this._path + '_'
  }
  getModelKey (fileNo){
    return consts('SEARCH_MODEL_KEY') + this._getPathKey() + fileNo.toString();
  }

  _getMapFileName(){
    if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
      return consts("SEARCH_MAP_ADDR_OLD");
    }
    return consts("SEARCH_MAP_ADDR");
  }
  getMapFilePath(){
    let fileName = this._getMapFileName()
    return this._getRelativePath(fileName);
  }
  getIndexFilePath() {
    let fileName = consts("SEARCH_INDEX_FILE");
    if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
      fileName = consts("SEARCH_INDEX_FILE_OLD");
    }
    return this._getRelativePath(fileName);
  }
  getTopicUrl(url) {
    return this._getRelativePath(url)
  }
  getSearchDbFilePath(){
    let fileName = consts("SEARCH_DB_FILE")
    return this._getRelativePath(fileName);

  }
  getMetadataFilePath(){
    let fileName = consts("SEARCH_METADATA_FILE")
    return this._getRelativePath(fileName);
  }

  getTextFilePath(id) {
    let folderPath = consts("SEARCH_TEXT_FILE")
    let relFolderPath = this._getRelativePath(folderPath)
    return `${relFolderPath}/${id}.js`
  }

}
module.exports = Paths
