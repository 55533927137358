let rh = require("../../lib/rh")
let _ = rh._
let consts = rh.consts
//const KEY_ACTIVE_BOOKID = 'active_bookid';

class TocBreadcrumbsController {

  constructor(widget) {
    this.widget = widget;
    //this.widget.model.subscribe(KEY_ACTIVE_BOOKID, this.updateBreadcrumbs)
  }
  select(id, url){
    this.widget.publish(consts('KEY_TOC_SELECT_ITEM'),
      {'id': id , 'url': url});
  }
  getLink(id){
    if(id){
      let breadcrumbs = this.widget.get(consts('KEY_TOC_BREADCRUMBS'))
      let item = breadcrumbs[id]
      if(item && item.hasUrl){
        return item.url
      }
    }
  }
  goToHome(index){
    if(index){
      let breadcrumbs = this.widget.get(consts('KEY_TOC_BREADCRUMBS'))
      let item = breadcrumbs[index]
      if(item && !item.hasUrl){
        _.goToHome({rhtocid: item.id});
      }
    }
  }
}

rh.controller('TocBreadcrumbsController', TocBreadcrumbsController)