let _exports = {
  MAP:{
    WORD:0,
    HASH:1,
    COUNT:2,
    MODEL_FILE_NO:3,
    MODEL_INDEX:4,
    TOPICS:5
  },

  MODEL :{
    WORD_HASH:0,
    COUNT: 1,
    NEXT_MODEL: 2
  },

  FIRST_PREDICTION:{
    NEXT:0,
    PREVIOUS:1
  }
};

module.exports = _exports;