const rh = require("../../../../lib/rh")
const _ = rh._
const consts = rh.consts;
const history_reader = require('./history_reader');
const InputParser = require('./input_parser');
const PredictonMerger = require('../common/merger');
const TopicCounter = require('./topic_counter');
const search_utils = require('./utils');
const search_consts = require('./consts');
const Seamaphore = require('./../../../common/counting_seamaphore');
const PredictorCreator = require('./predictors_creator');
const SearchHandler = require('../topic/handler');

const max_predictions = 5;

class SearchController {
  constructor(widget) {
    this.widget = widget;
    this._predictors = [];
    this._correctors = [];
    this._previous_predictors = [];
    this.initHandleKeyHash();
    this.max_predictions = max_predictions;
    this.predicton_merger = new PredictonMerger([])
    widget.model.subscribe(consts('KEY_PROJECT_LIST'), () => {
      this.init();
    });

  }
  init() {
    let history_list = rh.storage.fetch(consts('PREV_SEARCH_KEY'));
    history_reader.initHistory(history_list);
    this.create_predictors()
    this.widget.model.csubscribe('EVT_CLOSE_SEARCH_SUGGESTION', this.closeSuggestions.bind(this))
    if(!rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
      this.search_handler = new SearchHandler(this.widget)
      this.widget.model.csubscribe('EVT_SEARCH_TERM', this.search.bind(this))
      this.widget.model.csubscribe('EVT_QUERY_SEARCH_RESULTS', this.search.bind(this))
    }
  }
  search()
  {
    if (!rh.rhs.doSearch())
    {
      let text = this.widget.get(consts('KEY_SEARCH_TERM'))
      this.search_handler.showSearchResults(text)  
    }
  }
  create_predictors() {
    this._predictors = []
    let predictor_creator = new PredictorCreator(history_reader, this.processResult.bind(this))
    predictor_creator.createPredictors(this.addPredictor.bind(this),
      this.addPrevPredictor.bind(this),
      this.addCorrector.bind(this))
  }
  addPredictor(predictor) {
    this._predictors.push(predictor)
  }
  addPrevPredictor(predictor) {
    this._previous_predictors.push(predictor)
  }
  addCorrector(corrector) {
    this._correctors.push(corrector)
  }
  showSuggestions(keyEvt) {
    this._initSuggestions(keyEvt);
    this._calculateSugguestions();
  }

  _initSuggestions(keyEvt) {
    this._initResult();
    this._initInputs(keyEvt);
    this._resutSeamaphore = new Seamaphore(this.onResultComplete.bind(this));
  }
  _initInputs(keyEvt) {
    this.parsed_input = new InputParser(keyEvt.text, false, keyEvt.selStart, false);
    if (search_utils.isPrevious(keyEvt)) {
      this._previous_input = new InputParser(keyEvt.text, false, keyEvt.selStart, true);
    }
  }
  _calculateSugguestions() {
    this._resutSeamaphore.wait(this._predictorCount());
    _.each(this._predictors, (predictor) => {
      predictor.getPredictions(this.parsed_input);
    });
    if (this._previous_input) {
      _.each(this._previous_predictors, (predictor) => {
        predictor.getPredictions(this._previous_input);
      });
    }
  }
  _clearPredictors() {
    _.each(this._predictors, (predictor) => {
      predictor.clear();
    });
    _.each(this._previous_predictors, (predictor) => {
      predictor.clear();
    });
    _.each(this._correctors, (corrector) => {
      corrector.clear();
    });
  }
  _predictorCount() {
    let count = this._predictors.length;
    if (this._previous_input) {
      count += this._previous_predictors.length;
    }
    return count;
  }
  _initResult() {
    if (this.predicton_merger) {
      this.predicton_merger.clear();
    }
    this.widget.publish("selected", undefined);
    this._previous_input = undefined;
    this._computing_correction = false;
  }
  _clearResult() {
    this._resetMovement();
    this._previous_input = undefined;
    this.parsed_input = undefined;
    this._resutSeamaphore = undefined;
    this._clearPredictors()
  }

  isSuggestionsOpen() {
    let predictions = this.widget.get(consts("SEARCH_RESULTS_KEY"))
    return predictions && predictions.length > 0
  }
  processResult(predictionList, loader) {
    if (!predictionList.isForInput(this.parsed_input, this._previous_input)) {
      return;
    }
    if (!predictionList.isEmpty()) {
      this.predicton_merger.merge(predictionList.predictions);
      let counter = new TopicCounter(loader);
      this.predictions = counter.computeCounts(this.predictions, this.parsed_input);
    }
    this._resutSeamaphore.signal();
  }
  onResultComplete() {
    if (this.needCorrection()) {
      this.doCorrection();
    } else {
      this.predictions.splice(this.max_predictions);
      this.widget.publish(consts("SEARCH_RESULTS_KEY"), this.predictions);
      this._clearResult();
    }
  }
  needCorrection() {
    return !this._computing_correction &&
      this.predictions.length === 0 &&
      this.parsed_input.trimmedText !== "";
  }
  doCorrection() {
    this._computing_correction = true;
    this._resutSeamaphore = new Seamaphore(this.onResultComplete.bind(this));
    this._resutSeamaphore.wait(this._correctors.length)
    _.each(this._correctors, (corrector) => {
      corrector.getCorrections(this.parsed_input);
    });
  }
  canDelete(id) {
    let prediction = this.predictions[id];
    if (prediction === undefined) {
      return false;
    }
    return prediction.source === search_consts.PREDICTOR_SOURCE_ID.HISTORY_PREDICTOR;
  }
  handleDelete(id) {
    let prediction = this.predictions[id];
    this._deleteFromHistory(prediction.term);
    this._removePrediction(id);
    this.widget.publish("history_deleted", true);
  }

  _removePrediction(id) {
    this.predictions.splice(id, 1);
    this.widget.publish(consts("SEARCH_RESULTS_KEY"), this.predictions);
  }

  handleClick(id) {
    let history_deleted = this.widget.get("history_deleted");
    if (history_deleted) {
      return;
    }
    let prediction = this.predictions[id];
    if (prediction === undefined) {
      return;
    }

    let new_text = prediction.term;
    this.triggerSearch(new_text)
  }

  triggerSearch(term) {
    this._addToHistory(term);
    this.closeSuggestions();
    this.widget.publish(consts('KEY_SEARCH_TERM'), term);
    this.widget.publish(rh.consts('EVT_SEARCH_TERM'), true);
  }

  handleFocusOut(key, focus_key) {
    let history_deleted = this.widget.get("history_deleted");
    if (!history_deleted) {
      this.widget.publish(key, false);
    } else {
      this.widget.publish("history_deleted", false);
      this.widget.publish(focus_key, true);
    }
  }
  getFirstSuggestionIndex(length, down) {
    return down ? 0 : length - 1;
  }

  get predictions() {
    return this.predicton_merger.items;
  }
  set predictions(predictions) {
    this.predicton_merger = new PredictonMerger(predictions);
  }

  getNextSuggestionIndex(curr_selected, length, down) {
    let move = down ? 1 : length - 1;
    curr_selected = (curr_selected === undefined) ? 0 : curr_selected;
    curr_selected = (curr_selected + move) % length;
    return curr_selected;
  }

  handleArrowKey(keyEvt) {
    let curr_selected = this.widget.get("selected");
    let down = keyEvt.keyCode === 40;
    let length = this.getPredictionsLength();
    if (length > 0) {
      if (curr_selected === undefined) {
        curr_selected = this.getFirstSuggestionIndex(length, down);
      } else {
        curr_selected = this.getNextSuggestionIndex(curr_selected, length, down);
      }
      this.widget.publish("selected", curr_selected);
      let new_text = this.getNewInputText(this.results[curr_selected]);
      this.widget.publish(consts('KEY_SEARCH_TERM'), new_text);
    }
  }

  getNewInputText(prediction) {
    return prediction.term;
  }

  getPredictionsLength() {
    let length;
    this.results = this.widget.get(consts("SEARCH_RESULTS_KEY"));
    if (this.results) {
      length = this.results.length;
    }
    return length;
  }

  closeSuggestions() {
    this._clearResult();
    this.widget.publish("selected", undefined);
    this.original_parsed_input = undefined;
    this.widget.publish(consts("SEARCH_RESULTS_KEY"), []);
  }
  _deleteFromHistory(text) {
    history_reader.delete(text);
    this._saveHistory();
  }
  _addToHistory(text) {
    if (text && text !== "") {
      history_reader.add({
        text: text.trim(),
        count: 10
      }); // TODO change
      this._saveHistory();
    }
  }
  _saveHistory() {
    rh.storage.persist(consts('PREV_SEARCH_KEY'), history_reader.getHistory());
  }
  handleDefault(keyEvt) { 
    if (keyEvt.text === "") {
      this.closeSuggestions();
    } else {
      let substr = (keyEvt.text||'').substr(0, keyEvt.selStart)
      keyEvt.text = substr
      this.showSuggestions(keyEvt);
    }
  }
  handleShowSuggestion(keyEvt) {
    if (keyEvt.text === "" || search_utils.shouldShowSuggestion(keyEvt) === false) {
      this.closeSuggestions();
    } else {
      this.showSuggestions(keyEvt);
    }
  }
  handleReturn(keyEvt)
  {
    this.triggerSearch(keyEvt.text);
  }
  _isMovementKey(keyCode) {
    return [38, 39, 40].indexOf(keyCode) >= 0;
  }
  _resetMovement() {
    this.original_parsed_input = undefined;
    this.widget.publish("selected", undefined);
  }
  _isHandlingCursorMove() {
    let curr_selected = this.widget.get("selected");
    return curr_selected !== undefined;
  }
  initHandleKeyHash() {
    this.handle_key_hash = {
      up: this.handleArrowKey.bind(this),
      down: this.handleArrowKey.bind(this),
      backspace: this.handleBackSpace.bind(this),
      return: this.handleReturn.bind(this),
      default: this.handleDefault.bind(this),
      escape: this.handleEscape.bind(this),
      right: this.handleRight.bind(this)
    }
  }

  handleRight(keyEvt) {
    if (!this._isHandlingCursorMove()) {
      this._resetMovement()
      return this.handleShowSuggestion(keyEvt)
    }
    if (search_utils.shouldAppendSpace(keyEvt)) {
      keyEvt.text += " ";
      keyEvt.selStart += 1
      this.widget.publish(consts('KEY_SEARCH_TERM'), keyEvt.text);
    }
    this._resetMovement();
    this.handleShowSuggestion(keyEvt)
    return false
  }
  handleBackSpace(keyEvt) {
    return this.handleShowSuggestion(keyEvt);
  }
  handleEscape() {
    this.closeSuggestions();
  }
  handleKey(event) {

    let keyCode = event.keyCode;
    let keyEvt = {
      keyCode: keyCode,
      selStart: event.target.selectionStart,
      text: event.target.value
    }
    if (!this._isMovementKey(keyCode)) {
      this._resetMovement();
    }
    let index = _.getKeyIndex(keyCode);

    if (index === undefined) {
      return true;
    }
    let handleFn = this.handle_key_hash[index];
    if (handleFn) {
      return handleFn(keyEvt);
    }
  }
}

rh.controller('SearchController', SearchController)