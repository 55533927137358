let rh = require("../../lib/rh")
let _ = rh._
let consts = rh.consts
class SearchResultController {

  constructor(widget) {
    this.widget = widget
  }

  getLink(url) {
    let searchUrl = url 
    let highlightParam = this.widget.get(consts('KEY_SEARCH_RESULT_PARAMS'))
    let searchTerm = this.widget.get(consts('KEY_SEARCH_TERM')) 
    let searchTermParam = ''
    if(searchTerm &&  searchTerm !== '') {
      let key = consts('HASH_KEY_RH_SEARCH')
      let searchTermMap = {};
      searchTermMap[key] = searchTerm
      searchTermParam = _.mapToEncodedString(searchTermMap)
    }
    if(highlightParam !== undefined && highlightParam !== ''){
      searchUrl = searchUrl + highlightParam + '&' + searchTermParam 
    }
    else if(searchTermParam !== ''){
      searchUrl = searchUrl + '?' + searchTermParam
    } 
    return searchUrl
  }
}

rh.controller('SearchResultController', SearchResultController)