class SortedSet{
  constructor(set){
    this._set = set || [];
    this._initialised = false;
  }
  intersect(other){
    if(!Array.isArray(other)){
      other = other.set;
    }
    let new_set =  [];
    let count =0 ;
    let i = 0; let j= 0;
    for(; i < this._set.length && j < other.length;){
      if(this._set[i] < other[j]) {
        i++;
      }
      else if (this._set[i] > other[j]) {
        j++;
      }
      else {
        new_set[count++] = this._set[i];
        i++; j++;
      }
    }
    this._set = new_set;
  }
  intersectOrSet(other){
    if(this._initialised){
      this.intersect(other)
    }
    else{
      if(!Array.isArray(other)){
        other = other.set;
      }
      this.set = other
      this._initialised = true
    }
  }
  intersectCount(other){
    if(!Array.isArray(other)){
      other = other.set;
    }
    let count = 0;
    let i = 0;
    let j = 0;
    for(; i < this._set.length && j < other.length;){
      if(this._set[i] < other[j]) {
        i++;
      }
      else if (this._set[i] > other[j]) {
        j++;
      }
      else {
        count++;
        i++; j++;
      }
    }
    return count;
  }
  set set(set){
    this._set = set || [];
  }
  get set(){
    return this._set;
  }
  get length(){
    return this._set.length;
  }

}
module.exports = SortedSet;
