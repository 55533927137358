let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;
let { model } = rh;

model.publish(consts('KEY_SHOW_TAGS'), rh._params.showtags === 'true');
model.publish(consts('KEY_IFRAME_EVENTS'), {click: true});

let oldHashMap = {};
let hashChanged = function() {
  let newHashMap = _.hashParams();
  model.publish(consts('EVT_HASH_CHANGE'),
    {oldMap: oldHashMap, newMap: newHashMap},
    {sync: true});
  return oldHashMap = newHashMap;
};

let redirectToDefaultTopic = function() {
    let paramsStr = _.extractParamString(document.location.href);
    let paramsMap = _.urlParams(paramsStr);
    let hashStr = _.extractHashString(document.location.href);
    let hashMap = _.hashParams(hashStr);
    let mapnoattr = consts('RHMAPNO');
    let topicKey = consts('HASH_KEY_TOPIC');
    if (!(mapnoattr in paramsMap) && !(topicKey in hashMap)) {
      return document.location = consts('DEFAULT_TOPIC');
    }
  };

_.addEventListener(window, 'hashchange', hashChanged);

_.addEventListener(window, 'orientationchange', function(e) {
  model.publish(consts('EVT_ORIENTATION_CHANGE'), null);
  if (model.get(consts('KEY_SCREEN_IPAD'))) {
    let topicFrame;
    if (topicFrame = _.getTopicFrame()) {
      let { display } = topicFrame.style;
      topicFrame.style.display = 'none';
      topicFrame.offsetHeight;
      return _.defer(function() {
        topicFrame.style.display = display;
        return _.delay(function() {
          topicFrame.offsetHeight;
          return _.delay(() => // Let try once more
            topicFrame.offsetHeight
          
          , 500);
        }
        , 200);
      });
    }
  }
});
   

// TODO
//model.subscribe consts('EVT_ORIENTATION_CHANGE'), _.debounce ->
//  meta = $('meta[name=viewport]', 0)
//  meta.content = "width=#{window.innerWidth}, initial-scale=1,
//  maximum-scale=3, user-scalable=yes"
//  if model.get consts 'KEY_SCREEN_IOS'
//    model.publish consts('EVT_RELOAD_TOPIC'), null
//, 250

model.subscribe(consts('EVT_WIDGET_LOADED'), function() {
  // Create ExpressionBuilder widget on every page
  let topicFrame;
  model.subscribe(consts('KEY_MERGED_FILTER_KEY'), () => new rh.widgets.ExpressionBuilder());

  // Create BrowseSequenceBuilder widget on every page
  new rh.widgets.BrowseSequenceBuilder();

  hashChanged();

  // replace default print method to support iFrame
  rh.layoutPrint = window.print;
  window.print = () =>
    // publish it with null value, to stop the autmatic subscribe calls
    model.publish(consts('EVT_PRINT_TOPIC'), null)
  ;

  let topicFileName = _.getFileName(document.location.href);
  if (topicFileName === consts('TOPIC_FILE')) {
    let redirectUrl = redirectToDefaultTopic();
    if (redirectUrl) { return redirectUrl; }
  }

  if (topicFrame = _.getTopicFrame()) {
    return topicFrame.onload = () => model.publish(consts('EVT_TOPIC_LOADED'), null);
  } else {
    let paramsStr = _.extractParamString(document.location.href);
    let paramsMap = _.urlParams(paramsStr);
    let mapnoattr = consts('RHMAPNO');
    if( mapnoattr in paramsMap ) {
      return document.location = consts('TOPIC_FILE') + "?" + paramsStr;
    }
  }
});
model.subscribe(`${consts('EVT_BOOKMARK')}#content_top_placeholder`, () =>
  // Hack #content_top_link clicks and send them to content iframe
  model.publish(consts('EVT_SCROLL_TO_TOP'), null)
);

model.subscribe(consts('KEY_PROJECT_LIST'), () => rh.filterObject = new rh.MergeProj);

model.subscribe(consts('EVT_PROJECT_LOADED'), function() {
  let deviceReady = "deviceready";
  return document.addEventListener(deviceReady, function() {
    if (window.cordova != null) { return model.publish(consts('KEY_MOBILE_APP_MODE'), true); }
  });
});

model.subscribe(consts('GO_TO_DEFAULT_TOPIC'), function ()
{
  _.goToDefaultTopic()
});
