let lunr = require ('lunr')
let Synonymns = require('../../common/synonymns')
const rh = require("../../../../../lib/rh")
const _ = rh._
const LanguageSetter = require('../language')

module.exports = class SearchDb{
  constructor(content, loader, settings){
    this.init(content, loader, settings)
  }
  init(content, loader, settings = {}) {
    content = this.fixContent(content)
    this.settings = settings
    this.content = content
    this.code =  _.get(this.settings, "language.code")
    this.languageSetter = new LanguageSetter(this.code, loader, this.loadDb.bind(this))
    this.languageSetter.setLanguage(lunr)
  }
  fixContent(content) {
    content.pipeline = content.pipeline || []
    content.fieldVectors = content.fieldVectors || []
    content.invertedIndex = content.invertedIndex || []
    return content
  }
  loadDb(){
    this.processSyn()
    this.registerFunctions()
    this.db = lunr.Index.load(this.content)
    if(!_.isEmptyObject(this.content.pipeline)) {
      this.languageSetter.replaceFn(this.db, lunr)
    }
  }
  registerFunctions() {
    let pipelineFunction = this.replaceSynonymn.bind(this)
    let addQueryFunction = this.addQuery.bind(this)
    lunr.Pipeline.registerFunction(pipelineFunction, 'replaceSynonymn')
    lunr.Pipeline.registerFunction(addQueryFunction, 'addQuery')
  }

  processSyn() {
    let synonymns_words = (this.settings && this.settings.synonyms) || {}
    let synonyms = new Synonymns(synonymns_words)
    this.settings.rootWords = synonyms.rootWords
  }
  addQuery(token) {
    let word = token.toString()
    this.queryWords.push(word)
    this.queryWords = _.unique(this.queryWords)
    return token
  }
  replaceSynonymn(token) {
    let word = token.toString()
    let root = this.settings && this.settings.rootWords && this.settings.rootWords[word]
    if (root && root !== word) {
      return token.update(() => { return this.settings.rootWords[word] })
    }
    else {
      return token
    }
  }
  addStopWords(index, stopWords) {
    if (stopWords) {
      let stopWordFilter = lunr.generateStopWordFilter(stopWords)
      index.pipeline.before(lunr.stopWordFilter, stopWordFilter)
      index.pipeline.remove(lunr.stopWordFilter)
    }
  }
  search(query) {
    this.queryWords = []
    let params = query.getParams(this.isStopWord.bind(this)).trim()
    let search_results = rh._.isEmptyString(params) ? [] : this.db.search(params)
    search_results = search_results.sort((a, b) => rh._.keys(b.matchData.metadata).length - rh._.keys(a.matchData.metadata).length)
    return (query.opts)?
      _.filter(search_results, (result) => this.match(result, query.opts))
      : search_results
  }

  match(result, opts) {
    let retVal = true
    if (opts.andSearch) {
      let matchingWords = result && result.matchData.metadata || []
      _.each(this.queryWords, (word) => {
        if (!matchingWords[word] && !this.isStopWord(word)) {
          retVal = false
        }
      })
    }
    return retVal
  }

  isStopWord(word){
    return this.settings.stopWords.indexOf(word.toLowerCase()) !== -1
  }

  searchGlossary(query) {
    let glossary = (this.settings && this.settings.glossary) || {}
    let queryTokens = this.getRootTokens(query)
    let gossaryResults = _.reduce(glossary, (result, definition, term) =>{
      let gloTokens = this.getRootTokens(term)
      if (queryTokens.length === gloTokens.length && this.matchTokens(queryTokens, gloTokens)) {
        result[term] = definition
      }
      return result
    }, {})
    return gossaryResults
  }

  matchTokens(set1, set2) {
    let matched = true
    _.each(set1, (term1, index) => {
      if (term1 !== set2[index]) {
        matched = false
      }
    })
    return matched
  }

  getRootTokens(text) {
    let tokens = lunr.tokenizer(text)
    return _.map(tokens, (token) => {
      let word = token.toString()
      return this.settings.rootWords[word] || word
    })
  }

  export(){
    return this.db.toJSON()
  }
}
