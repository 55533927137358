const rh = require("../../../../lib/rh")
const _ = rh._
module.exports = class SearchTextMerger {
  constructor(nextTexts, texts, filter) {
    this.nextTexts = nextTexts
    this.texts = texts
    this.filter = filter || this.defaultFilter
  }
  join(id, start, end) {
    let texts = []
    for (let i = start; i <= end; i++){
      if (this.texts[id][i]) {
        texts.push(this.texts[id][i])
      }
    }
    return texts.join("").replace(/[\n ]+/g, ' ').replace(/\u00A0/gi, " ")
  }
  defaultFilter() {
    return false
  }
  getIndexText(texts) {
    return texts.join(" ").replace(/\n/gi, " ").trim()
  }
  getText() {
    let texts = []
    _.each(this.nextTexts, item => {
      if (!this.filter(item[0])) {
        texts.push(this.join(item[0], item[1], item[2]))
      }
    })
    return texts.join("")
  }
}