const rh = require("../../../../../lib/rh")
const _ = rh._

module.exports = class CbtFilter {
  constructor(fields, origin, tagExprs) {
    this.fileds = fields
    this.origin = origin
    this.tagExprs = tagExprs
  }
  filter(itemList) {
    return _.reduce(itemList, (filtered, item) => filtered || this._isFilterd(item), false)
  }
  _isFilterd(item) {
    let cbt = this.getCbt(item)
    return cbt && this._isFilteredCbt(cbt)

  }
  _isFilteredCbt(cbt) {
    let ids = cbt.split(';')
    return !_.any(ids, (id)  => {
      return _.evalTagExpression(id, this.tagExprs, this.origin)
    })
    
    
  }
  getCbt(item) {
    return item && item.attributes
      && item.attributes.length > 0 && _.map(item.attributes, attr => {
        return attr.value
      }).join(";")
  }
}