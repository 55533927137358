import search_utils from './utils'
class Prediction {
  constructor(term, probability, count, source){
    this.probability = probability;
    this.term = term;
    this.count = count;
    this.source = source;
  }
  merge(new_item) {
    this.probability += new_item.probability;
    this.count = Math.max(this.count, new_item.count);
  }
  compare(other) {
    return other.probability - this.probability
  }
  match(other) {
    return search_utils.compareNoCase(other.term, this.term) === 0;
  }
}

module.exports = Prediction;