var JsLoadingController = (function() {
  let rh = undefined;
  let $ = undefined;
  let _ = undefined;
  JsLoadingController = class JsLoadingController {
    static initClass() {
      ({ rh } = window);
      ({ $ } = rh);
      ({ _ } = rh);
    }

    constructor(widget, opts) {
      let removeClasses = (opts != null ? opts.removeClasses : undefined) || ['hide-children', 'loading'];
      _.each(removeClasses, className => $.removeClass(widget.node, className));
      _.each(opts != null ? opts.addClasses : undefined, className => $.addClass(widget.node, className));
    }
  };
  JsLoadingController.initClass();
  return JsLoadingController;
})();

window.rh.controller('JsLoadingController', JsLoadingController);
