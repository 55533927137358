let rh = require("../../../../lib/rh")
let _ = rh._;

class Merger{
  constructor(items, max_items){
    this._items = items; 
    this._max_items = max_items || rh.model.get(rh.consts('MAX_SEARCH_RESULTS'))|| 20;
  }
  get items(){
    return this._items
  }
  merge(newItems){
    _.each(newItems, (item) => {
      this.mergeItem(item);
    });
    this._sort();
    this._purge();
  }
  clear(){
    this._items = [];
  }
  _purge(){
    if(this._items.length > this._max_items){
      this._items.splice(this._max_items);
    }
  }
  mergeItem(item){
    let existing_item = this.find_item(item);
    if(existing_item){
      existing_item.merge(item)
    }
    else{
      this._items.push(item);
    }
  }

  find_item(match_item) {
    return _.find(this._items, (item) => {
      return item.match(match_item)
    });
  }
  
  merge_item(existing_item, new_item) {
    existing_item.probability += new_item.probability;
    existing_item.count = Math.max(existing_item.count, new_item.count);
  }

  _sort(){
    this.items.sort(this.compare_items);
  }
  compare_items(p1, p2){
    return p1.compare(p2)
  }
}

module.exports = Merger;
