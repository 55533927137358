const INDEX = require('./indices');
const rh = require("../../../../lib/rh")
const _ = rh._;
const Prediction = require('./prediction');
const search_consts = require('./consts');
const search_utils = require('./utils');
const PredictionList = require('./prediction_list');


class Corrector{
  constructor( loader, callback, max_predictions = 20){
    this._loader = loader
    this.predictions = [];
    this.max_predictions = max_predictions;
    this._callback = callback;
  }
  getCorrections(parsed_input){
    this._parsed_input = parsed_input;
    this.predictions = [];
    this._computePredictions();
    let hashes = this._loader.getHashes(this._parsed_input._partial)
    if(hashes){
      let hash_count = hashes.length;
      for(let i = 0; i < hash_count && this.predictions.length < this.max_predictions; i++){
        let hash = hashes[i];
        this._addCorrection(hash);
      }
    }
    this.onResultCalculated();
  }
  clear(){
    this.predictions = []
  }
  _computePredictions(){

    let word_hashes = this._loader.getHashes(this._parsed_input._partial);
    if(word_hashes){
      this._addCorrection(word_hashes);
    }
  }
  onResultCalculated(){
    if(this._callback) {
      this._callback(new PredictionList(this.predictions, this._parsed_input), this._loader);
    }
  }

  _matchCorrection(word){
    this._hamming_dist = this._getDistance(word);
    if(this._hamming_dist <= 2 && !this._loader.isStopWord(word)){
      return true;
    }
    return false;
  }

  _addCorrection(word_hash){
    if(!word_hash) {
      return;
    }
    let word = search_utils.getSafeElement(word_hash, INDEX.MAP.WORD);
    if(!word) {
      return;
    }

    if(this._matchCorrection(word)){
      let complete_word = this._parsed_input.replaceLastWord(word)
      let term = this._parsed_input.completeSuggestion(complete_word)
      let prediction = new Prediction(term, 1/this._hamming_dist, 2, search_consts.PREDICTOR_SOURCE_ID.CORRECTOR );
      this.predictions.push(prediction);
    }
  }
  _getDistance(word){
    return _.findEditDist(this._parsed_input._partial, word);
  }
}

module.exports = Corrector;
