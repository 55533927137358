
const rh = require("../../../../../lib/rh")
const _ = rh._
const Paths = require('../../common/paths')
module.exports = class SearchLoader{
  constructor(path = ""){
    this._paths = new Paths(path)
  }
  getPath(){ 
    return this._paths.getPath()
  }
  init(id, callbackFn) {
    this.id = id
    callbackFn(this)
  }
  loadDB(callbackFn){
    let path = this._paths.getSearchDbFilePath()
    _.loadScript(path, true, () => {
      let data = _.exports()
      if(callbackFn){
        callbackFn(data)
      }
    }, true);
  }

  getId(id) {
    return `${this.id}_${id}`
  }
  getUrl(url) {
    return this._paths._getRelativePath(url)
  }
  loadMetaData(callbackFn){
    let path = this._paths.getMetadataFilePath()
    _.loadScript(path, true, () => {
      let data = _.exports()
      if(callbackFn){
        callbackFn(data)
      }
    }, true);
  }
  
  loadTextData(query, id, callbackFn) {
    let path = this._paths.getTextFilePath(id)
    _.loadScript(path, true, () => {
      let data = _.exports()
      if (callbackFn) {
        callbackFn(query, id, data)
      }
    }, true);
  }
}
