module.exports = class SearchMetadata {
  constructor(data) {
    if(data) {
      this.data = JSON.parse(data)
    }
  }
  get settings() {
    return this.data.settings
  }
  get topicData() {
    return this.data.metadata
  }
  getTopicData(id) {
    return this.topicData[id]
  }

  getTopicSummary(id) {
    return this.topicData[id] && this.topicData[id].summary
  }

  getTopicNextId(id) {
    return this.topicData[id] && this.topicData[id].next
  }

  get context() {
    return this.settings.context
  }

  get contextLimit() {
    return this.settings.contextLimit
  }

  get fields() {
    return this.settings.fields
  }
}