const Loader = require('./loader');
const rh = require("../../../../lib/rh")
const _ = rh._
const HistoryPredictor = require('./history_predictor');
const NgramPredictor = require('./ngram_predictor');
const GeneralPredictor = require ('./general_predictor');
const Corrector = require('./corrector');
const search_consts = require('./consts');

//this.processResult.bind(this)
class PredictorsCreator{
  constructor(history_reader, processResultFn){
    this._history_reader = history_reader
    this._processResultFn = processResultFn
  }

  get projectPaths(){
    return rh.model.get(rh.consts('KEY_PROJECT_LIST'))
  }
  
  _createLoaders() {
    return _.map(this.projectPaths, (path) => {
      return new Loader(path)
    })
  }
  createPredictors(addFn, prevAddFn, addCorrectorFn){
    this._addFn = addFn
    this._prevaddFn = prevAddFn
    this._addCorrectorFn = addCorrectorFn
    let history_predictor = new HistoryPredictor(this._history_reader, this._processResultFn);
    this._addFn(history_predictor);

    let loaders = this._createLoaders()
    _.each(loaders, (loader) => {
      loader.init(this.addPredictors.bind(this))
    });
  }
  addPredictors(loader){
    for (let i = 1; i <= loader.nGram; i++){

      let ngp = new NgramPredictor({loader, level:i, callback:this._processResultFn});
      ngp.init();
      this._addFn(ngp)
    }
    for (let i = 1; i <= search_consts.previousnGram; i++){
      let ngp = new NgramPredictor({loader, level:i, callback:this._processResultFn, previous:true});
      ngp.init();
      this._prevaddFn(ngp);
    }

    let general_predictor = new GeneralPredictor(this._processResultFn, loader)
    this._addFn(general_predictor);

    let corrector = new Corrector(loader, this._processResultFn)
    this._addCorrectorFn(corrector);

  }
}
module.exports = PredictorsCreator
