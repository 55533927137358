const QueryProcessor = require('./query/processor')
const rh = require("../../../../lib/rh")
const _ = rh._
const consts = rh.consts
const Loader = require('./query/loader')
const ResultMerger = require('../common/merger')
const Seamaphore = require('../../../common/counting_seamaphore')
const SearchResult = require('./result')
let $ = rh.$
module.exports = class SearchHandler{
  constructor(widget, max_results =20) {
    this.widget = widget
    this.initDone = false
    this.max_results = max_results;
    this.queryProcessors = []
    this.merger = new ResultMerger([])
    this._resutSeamaphore = new Seamaphore(this.onResultComplete.bind(this));
    this.widget.model.subscribe(consts('KEY_PROJECT_LIST'), () => {
      this.init()
    })
  }
  
  init() {
    if (!this.initDone) {
      let loaders = this._createLoaders()
      _.each(loaders, (loader, id) => {
        loader.init(id, this.addProcessors.bind(this))
      })
      this.initDone = true
      if(this.pendingText){
        this.showSearchResults(this.pendingText)
        this.pendingText = null
      }
    }
  }

  addProcessors(loader) {
    this.queryProcessors.push(new QueryProcessor(loader))
  }
  get projectPaths() {
    return rh.model.get(rh.consts('KEY_PROJECT_LIST'))
  }

  _createLoaders() {
    return _.map(this.projectPaths, (path) => {
      return new Loader(path)
    })
  }

  showSearchResults(text) {
    if(!this.initDone){
      this.pendingText = text
      return
    }
    let isNewQuery = _.find(this.queryProcessors, processor => processor.isNewQuery(text, this.searchOpts(processor.getPath())))
    if(isNewQuery){
      this.merger = new ResultMerger([])
      this._resutSeamaphore = new Seamaphore(this.onResultComplete.bind(this));
      this._resutSeamaphore.wait(this.processorCount)
      //rh.model.publish(rh.consts('EVT_SEARCH_IN_PROGRESS'), true, {sync: true});
      //rh.model.publish(rh.consts('KEY_SEARCH_PROGRESS'), 0, {sync: true});
      _.each(this.queryProcessors, processor =>{
        processor.search(text, this.searchOpts(processor.getPath()), this.topicCallback.bind(this), this.onSearchResults.bind(this))
      })
    }
  }
  
    
  
  get processorCount() {
    return this.queryProcessors.length
  }
  onSearchResults(text, searchResult, glossary_results) {
    this.processTopicsResults(text, searchResult)
    this.processGlossaryResults(text, glossary_results)
    this._resutSeamaphore.signal()
  }

  onResultComplete() {
    this.widget.publish(rh.consts('EVT_SEARCH_IN_PROGRESS'), false, {
      sync: true
    });
    var searchTerm = this.widget.get(consts('KEY_SEARCH_TERM'))
    var searchTermMap = {[consts('HASH_KEY_RH_SEARCH')]: searchTerm}
    let resultsParams = _.mapToEncodedString(searchTermMap)
    this.widget.publish(consts('KEY_SEARCH_RESULT_PARAMS'), resultsParams)
    this.widget.publish('.p.searchresults', this.merger.items)
    if(window._textToHtml_nonbsp) {
      this.showSearchMsg(this.merger.items.length, window._textToHtml_nonbsp(searchTerm))
    }
    if (rh.model.get(consts('KEY_SHOW_CONTEXT')) === false)
    {
      this.hideContexts()
    }
  }

  showSearchMsg(a, b){
    var c = rh.model.get(rh.consts("KEY_LNG"));
    c = c.ResultsFoundText
    c = c.replace("%1", a)
    c = c.replace("%2", "'" + b + "'")
    this.displayMsg(c)
  }

  displayMsg(a){
    var b = document.getElementById("searchMsg");
    if(b){
      b.innerHTML = a
    }
  }

  hideContexts()
  {
    let srchCtxts = document.getElementsByClassName("wSearchContext")
    _.defer(() =>{ 
      _.each(srchCtxts, ctxtNode =>{
        $.addClass(ctxtNode, 'rh-hide');
      })
    })
    
  }

  searchOpts(path) {
    return {
      andSearch: rh.model.get(rh.consts('KEY_AND_SEARCH')) === '1',
      cbt: rh.model.cget('KEY_TAG_EXPRESSION'),
      origin: path
    }
  }
  topicCallback(id, summary, url) {
    this.widget.publish(`.p.searchsummary${id}`, summary)
    this.widget.publish(`.p.searchurl${id}`, url)
  }
  processGlossaryResults(text, glossary_result) {
    let term = glossary_result && Object.keys(glossary_result)[0]
    this.widget.publish(consts('KEY_GLOSSARY_RESULT_TERM'), term)
    this.widget.publish(consts('KEY_GLOSSARY_RESULT'), glossary_result[term])
  }

  processTopicsResults(text, topicResults) {
    this.text = text
    let processedResult = _.map(topicResults, result => new SearchResult(result))
    this.merger.merge(processedResult)
  }
  
}