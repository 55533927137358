
const rh = require("../../lib/rh")
const consts = rh.consts

class FavoritesController{
  constructor(widget){
    this.widget = widget;
    this.favStorage = new rh.Storage()
    /* Internal functions and variables */
    this._loadFromStorage = false;/* Favorites already loaded from storage? */

    this._favMemoryStore = undefined;/* Variable to hold favorites object */
    rh.model.subscribeOnce(consts('EVT_PROJECT_LOADED'), () =>{
      this.init();
    });
  }
  _getUniqueId() {
    function _p8(s) {
      const p = (`${Math.random().toString(16)}000000000`).substr(2,8);
      return s ? `-${p.substr(0,4)}-${p.substr(4,4)}` : p ;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
  }

  numberOfItems(JSON) {/* Count the number of items in an object */
    return Object.keys(JSON).length;
  }
  _getFavHTMLElements(type) {
      /* Find all elements in the HTML that can hold a favorite widget */

    let allElements = document.getElementsByTagName("div");/* Only divs can be favorite holders */
    let elements = [];

    for(let i=0; i<allElements.length; i++) {
      if(allElements[i].getAttribute(rh.consts('FAVATTRIBUTE')) === type) {
        elements.push(allElements[i]);
      }
    }

    return elements;
  }

  getLinkTitle(isFavorite){
    let lng = rh.model.get(consts('KEY_LNG'))
    if(isFavorite){
      return (lng && lng.unsetAsFavorite) || '';
    }
    else
    {
      return (lng && lng.setAsFavorite) || '';
    }
  }


  _loadFavoritesFromStorage(){
      /* Load setting from storage */
    let storageObject;
    let loadString = this.favStorage.fetch(consts('FAVSTORAGE'));

    if(typeof(loadString) === "undefined" || loadString === null) {/* No settings yet, create new object. */
      storageObject = {};
    } else {
      storageObject = JSON.parse(loadString);/* Parse string to object */
    }
    return storageObject;
  }

  _loadFavorites(){
    if(this._loadFromStorage === false) {/* Only load once, then use object in memory. Saves a few parsings. */
      this._favMemoryStore = this._loadFavoritesFromStorage();
    }
    return this._favMemoryStore;
  }

  _saveFavorites(){
    let favoritesString;
    favoritesString = JSON.stringify(this._favMemoryStore);
    this.favStorage.persist(rh.consts('FAVSTORAGE'), favoritesString);
  }

  _unFavoriteTopic(topic){
    for(const i in this._favMemoryStore) {
      const current = this._favMemoryStore[i];
      if(current.topic ===  topic) {
        delete this._favMemoryStore[i];
        break;
      }
    }
    this._saveFavorites();
    rh.model.publish(rh.consts('EVENTFAVCHANGE'), null);/* Publish so favorites subscribers are notified of changes */
  }

  _favoriteTopic(url, title){
    //let id;
    this._favMemoryStore[this._getUniqueId()] = {"topic" : url, "title" : title};
    this._saveFavorites();
    rh.model.publish(rh.consts('EVENTFAVCHANGE'), null);/* Publish so favorites subscribers are notified of changes */
  }

  /* Public functions: for use in other scripts */
  getStorageId (){ return `fav-${rh._.getHostFolder()}` }

  getTopicURL(){ /* Get the URL of the current topic */
    return rh.model.get(rh.consts('KEY_TOPIC_URL'));
  }

  getTopicTitle(){ /* Get title of currently opened topic */
    return rh.model.get(rh.consts('KEY_TOPIC_TITLE'));
  }

  isTopicFavorite(topic){
      /* Is the current topic a favorite topic? */
    let favorites;
    let isFavorite;
    if(!topic) {
      topic = this.getTopicURL();
    }
    favorites = this._loadFavorites();

    isFavorite = false;
    for(const i in favorites) {
      const current = favorites[i];
      if(current.topic === topic) {
        isFavorite = true;
        break;
      }
    }

    return isFavorite;
  }
  setfavoritesTitle(isFavorite){
    let title = this.getLinkTitle(isFavorite)
    rh.model.publish(consts('FAVORITES_BUTTON_TITLE'), title)
  }
  setTopicFavoriteState(){
    rh.model.publish(consts('TOPIC_FAVORITE'), this.isTopicFavorite());
  }

  setFavoritesList(){/* Write the favorites list */
    //let favholders;
    let favorites = [];
    let id
    let favorites_map = this._loadFavorites();
    for(id in favorites_map){
      favorites.push(favorites_map[id]);
    }
    rh.model.publish(consts('KEY_FAVORITES'), favorites)
  }

  toggleFavorite(topicURL){/* Toggle whether the page is a favorite */
    if(!topicURL) {
      topicURL = this.getTopicURL();
    }
    if(this.isTopicFavorite(topicURL)) {
      this._unFavoriteTopic(topicURL);/* Because the favorites list can remove topics, removing a topic can be a different URL than the current topic. */
    } else {
      this._favoriteTopic(topicURL, this.getTopicTitle());/* This can only be the current topic */
    }
    this.setTopicFavoriteState();
  }

  init(){/* Initialise the favorites */

    this.favStorage.init(rh._.getRootUrl())

    /* Subscribe to topic events */
    rh.model.subscribe(rh.consts('KEY_TOPIC_URL'), this.setTopicFavoriteState.bind(this));
    rh.model.subscribe(rh.consts('EVENTFAVCHANGE'), this.setFavoritesList.bind(this));
    rh.model.subscribe(rh.consts('TOPIC_FAVORITE'), this.setfavoritesTitle.bind(this));

    this.setFavoritesList();
    rh.model.publish(consts('TOPIC_FAVORITE'), this.isTopicFavorite());
  }
}

rh.controller('FavoritesController', FavoritesController)
