let { rh } = window;
let { model } = rh;
let { _ } = rh;
let { consts } = rh;
let { http } = rh;
let { rhs } = rh;
let topics = 'topics';


class NavController {
  constructor() {
    this.updateData = this.updateData.bind(this);
  }

  lookup(children, key) {
    let element;
    if ((children != null) && (key != null)) {
      _.any(children, (child) =>  {
        if (child != null) {
          if ((key.type === child.type) && (key.name === child.name) &&
          _.isEqual(key.url, child.url)) {
            element = child;
          }
          return element != null;
        }
      }, this);
    }
    return element;
  }

  sort(array) {
    return array.sort((first, second) => window.compare(first.name, second.name));
  }

  updateData(event, constKey, projectUrl) {
    let curData = _.extractTempData(event, this.widget, {});
    this.addElement(curData, this.data, projectUrl, this.keys);
    if ((--this.chunkCount === 0) && (this.count === 0)) {
      if (constKey === 'PROJECT_INDEX_DATA') {
        this.data = this.sortIndexData(this.data)
      } else {
        this.sort(this.data)
      }
      return model.publish(consts(constKey), this.data);
    }
  }

  sortIndexData(data) { 
    let grpedData = _.groupBy(data, 'name')
    let mergedData = _.map(grpedData, keyWrdItems => this.mergedKeyWords(keyWrdItems))
    return mergedData = this.sort(mergedData)
  }

  mergedKeyWords(keyWrdItems) {
    return _.reduce(keyWrdItems, (result, item) => {
      result.name = item.name
      result.type = item.type
      result.url = item.url
      result['data-rhtags'] = item['data-rhtags']
      if (item.type === 'key') {
        result.topics = this.sortIndexData((result.topics || []).concat(item.topics || []))
        result.keys = this.sortIndexData((this.getKeys(result)).concat(item.keys || []))
      }
      return result
    }, {})
  }

  getKeys(result) {
    if (Array.isArray(result.keys)) { 
      return result.keys
    }
    return []
  }

  getChunkData(event, projectUrl, key) {
    this.count--;
    let tempData = _.extractTempData(event, this.widget, {});
    let arr = __guard__(tempData, x => x['chunkinfos']) || [];
    this.chunkCount += arr.length;
    return _.each(arr, function(chunk) {
      if (projectUrl) { projectUrl = _.ensureSlash(projectUrl); }
      return _.loadScript(`${projectUrl}whxdata/${chunk.node}.new.js`,
        false, (event) => {
          return this.updateData(event, key, projectUrl);
      });
    }
    , this);
  }

  loadNavData(type) {
    return model.subscribe(consts('KEY_PROJECT_LIST'), list => {
      this.alhpabet = '';
      this.count += list.length;
      return _.each(list, function(projectUrl) {
        if (projectUrl) { projectUrl = _.ensureSlash(projectUrl); }
        return _.loadScript(`${projectUrl}whxdata/\
${type === 'ndx' ? 'idx' : 'glo'}.new.js`, false, (event) => {
          return this.getChunkData(event, projectUrl, type === 'ndx' ? 'PROJECT_INDEX_DATA'
          : 'PROJECT_GLOSSARY_DATA'
          );
        });
      }
      , this);
    });
  }
}

rh.NavController = NavController;

var IndexController = (function() {
  let defProject = undefined;
  IndexController = class IndexController extends rh.NavController {
    static initClass() {
      defProject = '';
    }
    constructor(widget) {
      super();
      this.data = [];
      this.chunkCount = 0;
      this.count = 0;
      this.alhpabet = '';
      this.filter = '';
      this.keys = 'keys';
      this.widget = widget;

      model.subscribeOnce(consts('EVT_PROJECT_LOADED'), () => {
        this.loadNavData('ndx');
        (this.getIndexData)();
        return model.subscribe(consts('KEY_INDEX_FILTER'), () => {
          return this.alhpabet = '';
        });
      });
    }


    addElement(element, list, projectUrl, data) {
      if (element[data] != null) {
        return (() => {
          let result = [];
          _.each(element[data], (child) => {
            if (child != null) {var existing;

              if (!(existing = this.lookup(list, child))) {
                let obj = {type: child.type, name: child.name};
                if (child['data-rhtags']) {
                  obj['data-rhtags'] = `${child['data-rhtags']}+${projectUrl}`;
                }
                if (!child.url) { obj[topics] = []; }
                let relUrl = _.makeRelativeUrl(`${projectUrl}`, defProject);
                if (child.url != null) { obj.url = `${encodeURI(relUrl)}${child.url}`; }
                if ((child.url == null)) { obj[this.keys] = []; }
                list.push(obj);
                existing = list[list.length - 1];
              }
              this.addElement(child, existing[this.keys], projectUrl, this.keys);
              result.push(this.addElement(child, existing[topics], projectUrl, topics));
            }
          }, this);
          return result;
        })();
      }
    }


    showItem(name) {
      let filter = model.get(consts('KEY_INDEX_FILTER'));
      return !filter ||
       (name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
    }

    showCategory(name, level) {
      if (level > 0) { return false; }
      let ch = this.alphaText(name);
      if (this.alhpabet.indexOf(ch) > -1) { return false; }
      this.alhpabet += ch;
      return true;
    }

    showNextLevel(node) {
      let id = node.getAttribute('data-indexid');
      let old = this.widget.get(`show.${id}`);
      let childData = {[id]: !old};
      while (id) {
        id = id.substr(0, id.lastIndexOf('_'));
        childData[id] = true;
      }
      return this.widget.publish('show', childData);
    }

    alphaText(name) {
      return name.toUpperCase().charAt(0);
    }

    getIndexData() {
      return model.subscribeOnce(consts('KEY_PUBLISH_BASE_URL'), function() {
        try {
          let baseUrl = model.get(consts('KEY_PUBLISH_BASE_URL'));
          let baseUrlParts = rh._.splitUrl(baseUrl);
          let baseContext = baseUrlParts.pathname;
          if (baseContext && !_.isEmptyString(baseContext)) {
            let hashString = _.mapToEncodedString(_.extend(_.addPathNameKey({area: rhs.area(),
            prj: rhs.project(), type: (rhs.type)(), agt: 'ndx', mgr: 'agm'
            }))
            );
            return http.get(`${baseContext}?${hashString}`).success(function(data, status) {
              data = JSON.parse(data);
              let list = [];
              let rootPrjs = [];
              let projects = data.allProjects;
              let { masterProjects } = data;
              if (projects.length) { projects[0] = _.ensureSlash(projects[0]); }
              let makelist = (projectList, outList) =>
                _.each(projectList, item => outList.push(_.makeRelativeUrl(item, projects[0])))
              ;
              makelist(projects, list);
              makelist(masterProjects, rootPrjs);
              var updateList = function(item,index,list) {
                if(_.isEmptyString(item)) {
                  list[index] = '.';
                }
              };
              _.each(list, updateList);
              _.each(rootPrjs, updateList);
              model.publish(consts('KEY_PROJECT_LIST'), list);
              return model.publish(consts('KEY_MASTER_PROJECT_LIST'), rootPrjs);
            });
          }
        } catch (err) {
          if (rh._debug) { return rh._d('warn', err.message); }
        }
      });
    }
  };
  IndexController.initClass();
  return IndexController;
})();


rh.controller('IndexController', IndexController);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
