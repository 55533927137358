// Generated by CoffeeScript 1.9.1
(() => {
  var MergeProj, _, consts, model, rh;

  rh = window.rh;

  model = rh.model;

  _ = rh._;

  consts = rh.consts;

  MergeProj = ((() => {
    var group;

    function MergeProj() {
      this.tagCombs = [];
      this.idmap = {};
      this.filter = [];
      this.grpType = 'group';
      this.dataLoaded = false;
      this.loadTagData(model.get(consts('KEY_PROJECT_LIST')));
      this.caption = this.type = this.defFilter = void 0;
    }

    MergeProj.prototype.updateTagCombs = function(project) {
      var base, combs;
      combs = model.get(consts('KEY_PROJECT_TAG_COMBINATIONS'));
      if (combs != null) {
        project = _.parseProjectName(project);
        return (base = this.idmap)[project] != null ? base[project] : base[project] = combs;
      }
    };

    MergeProj.prototype.lookup = function(children, key, isGrpType) {
      var child, compKey, element, i, len;
      if (isGrpType == null) {
        isGrpType = true;
      }
      if ((children != null) && (key != null)) {
        for (i = 0, len = children.length; i < len; i++) {
          child = children[i];
          if (!(child != null)) {
            continue;
          }
          compKey = child.name || child.display;
          if (isGrpType === (child.children != null) && compKey === key) {
            element = child;
          } else if ((child.children != null) && !isGrpType) {
            element = this.lookup(child.children, key, isGrpType);
          }
          if (element != null) {
            break;
          }
        }
      }
      return element;
    };

    group = function(disp) {
      var object;
      return object = {
        type: 'group',
        display: disp,
        children: []
      };
    };

    MergeProj.prototype.addTag = function(childArr, tagElement) {
      var tag;
      if (this.lookup(this.filter, tagElement.name, false) == null) {
        tag = {
          display: tagElement.display,
          name: tagElement.name
        };
        return childArr.push(tag);
      }
    };

    MergeProj.prototype.addGroup = function(grpElement) {
      var element, grp, i, len, ref;
      grp = this.lookup(this.filter, grpElement.display);
      if (grp == null) {
        grp = group(grpElement.display);
        this.filter.push(grp);
      }
      ref = grpElement.children;
      for (i = 0, len = ref.length; i < len; i++) {
        element = ref[i];
        if (element != null) {
          if (element.children == null) {
            this.addTag(grp.children, element);
          }
        }
      }
      if (grp.children.length === 0 && this.filter.indexOf(grp) > -1) {
        return this.filter.splice(this.filter.indexOf(grp), 1);
      }
    };

    MergeProj.prototype.updateFilter = function(event) {
      var curfilter, element, i, len, results;
      curfilter = _.extractTempData(event, model, {});
      if (!this.caption) {
        this.caption = curfilter['caption'];
      }
      if (!this.type) {
        this.type = curfilter['type'];
      }
      if (typeof this.defFilter === 'undefined') {
        this.defFilter = curfilter['default'];
      }
      curfilter = curfilter['tags'] || [];
      results = [];
      for (i = 0, len = curfilter.length; i < len; i++) {
        element = curfilter[i];
        if (element != null) {
          if (element.children != null) {
            results.push(this.addGroup(element));
          } else {
            results.push(this.addTag(this.filter, element));
          }
        }
      }
      return results;
    };

    MergeProj.prototype.onLoadScript = function(event, project, count) {
      this.updateTagCombs(project);
      this.updateFilter(event);
      if (!count) {
        model.publish(consts('KEY_MERGED_FILTER_KEY'), this.filter);
        model.publish(consts('KEY_MERGED_PROJECT_MAP'), this.idmap);
        model.publish(consts('KEY_PROJECT_FILTER_CAPTION'), this.caption);
        model.publish(consts('KEY_PROJECT_FILTER_TYPE'), this.type);
        model.publish(consts('KEY_DEFAULT_FILTER'), this.defFilter);
        return this.dataLoaded = true;
      }
    };

    MergeProj.prototype.loadTagData = function(projList) {
      var count, i, len, project, results;
      if (!this.dataLoaded) {
        count = projList.length;
        results = [];
        let data = {project, count}
        for (i = 0, len = projList.length; i < len; i++) {
          project = projList[i];
          if (project != null) {
            if (project) {
              project = project + "/";
            }
            data.project = project
            results.push(
              _.loadScript(
                "" + project + (consts('PATH_PROJECT_TAGDATA_FILE')), 
                false,
                this.getLoadCallback(data)
              )
            );
          } else {
            results.push(void 0);
          }
        }
        return results;
      }
    };

    MergeProj.prototype.getLoadCallback = function(data) {
      let proj = data.project
      return (event) => this.onLoadScript(event, proj, --data.count);
    };

    return MergeProj;

  }))();

  rh.MergeProj = MergeProj;

}).call(this);
