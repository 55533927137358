const rh = require("../../../../../lib/rh")
const TextMerger = require('../text_merger')
const _ = rh._
module.exports = class SearchSummary {
  constructor(topicData, context,contextLimit) {
    this.topicData = topicData
    this.context = context
    this.contextLimit = Number.parseInt(contextLimit)
    this.rightLimit = Math.ceil((70 * this.contextLimit) / 100)
    this.leftLimit = Math.ceil((30 * this.contextLimit) / 100)
  }
  minDistance() {
    //_.each()
  }

  getSummary(id, matchResult, wordlist, data) {
    let summaries = []
    let summary = ''
    if (this.context) {
      summary = this.topicData[id] && (this.topicData[id].summary || "").substring(0, this.contextLimit)
    }
    try{
      summaries = _.map(matchResult, (metadata) => {
      
        return _.union(metadata, (posData, fieldId) => {
          if(!posData || !posData.position || !posData.position[0]){
            let texts = data[fieldId]
            return _.map(texts, text => this.trimRight(text))  
          }
          else{
            return _.union(posData.position, (fistrPos) => {
              if(fistrPos[0] !== undefined && fistrPos[1] !== undefined){
                let texts = data[fieldId]
                let merger = new TextMerger()
                let text = merger.getIndexText(texts)
                let word = text.substring(fistrPos[0], fistrPos[0] + fistrPos[1])
                word = word.toLowerCase()
                if (wordlist.indexOf(word) === -1) {
                  wordlist.push(word)
                }
                return this.getSummaryText(text, fistrPos[0], fistrPos[1], word)
              }
            })
          }
        })
      })
      if(!this.context || !summary || summary === ''){
        _.each(summaries[0] || [], s => {
          if (s.length > summary.length) {
            summary = s
          }
        })
      }
    }
    catch(e){
      summary = ''
    }
    if(summary === ''){
      summary = this.getDefaultSummary(data)
    }
    return summary
  }

  getDefaultSummary(data){
    let summary = ''
    try{
      let text = ''
      _.each(data, (value, key) =>{
        if(key !== 'id'){
          text = text + " "
        }
      }) 
      summary = this.getSummaryText(text, 0, this.contextLimit || 100, '')
    }
    catch(e){
      summary = ''
    }
    return summary
  }

  getSummaryText(text, idx, length, word){
    let leftText = text.substring(0, idx)
    let rightText = text.substring(idx + length)

    return `${this.trimLeft(leftText)}<b>${word}</b>
              ${this.trimRight(rightText)}`
  }
  trimLeft(leftText) {
    let leftIdx = leftText.indexOf(" ", leftText.length - this.leftLimit)
    leftText = leftText.substring(leftIdx, leftText.length)
    return leftText.trimStart()
  }
  trimRight(rightText) {
    let rightIdx = rightText.indexOf(" ", this.rightLimit)
    rightIdx = (rightIdx === -1) ? rightText.length : rightIdx 
    rightText = rightText.substring(0, rightIdx)
    return rightText.trimEnd()
  }
}