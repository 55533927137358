let rh = require("../../../../lib/rh")
let _ = rh._;
let PredictonCreator = require('./prediction_creator');
//let search_utils = require('./utils');
//let Prediction = require('./prediction');
let PredictionList = require('./prediction_list');
const search_consts = require('./consts');
const INDEX = require('./indices');

class NGramPredictor{
  constructor({loader, level, callback, previous, max_predictions = 20}){
    this.level = level;
    this.previous =  previous;
    this._max_predictions = max_predictions;
    this.predictions = [];
    this._callback = callback;
    this._loader = loader
    this._source = search_consts.PREDICTOR_SOURCE_ID.NGRAM_PREDICTOR;
  }

  init(){

  }
  clear(){
    this._word_model = undefined;
    this._prediction_array = undefined;
    this.parsed_input = undefined;
    this.predictions = [];
  }

  getPredictions(parsed_input) {
    this.parsed_input = parsed_input
    this.predictions = [];
    if(parsed_input !== undefined && (parsed_input.words.length >= this.level)){
      this.word_index = parsed_input.words.length - this.level;
      this._word = parsed_input.words[this.word_index];
      this._loadInputModel(this._word, this._compute_word_predictions.bind(this));
    }
    else{
      this._onResultsComputed();
    }
  }
  set max_predictions(newValue){
    this.max_predictions = newValue;
  }
  get max_predictions(){
    return this._max_predictions;
  }

  _compute_word_predictions(model_data){
    this.predictions = [];
    let level = this.level;
    let word = this._word;
    let total_count = this._loader.getCount(word);
    this._word_model = this._loader.getWordModel(word, model_data);
    if(this._word_model){
      this._prediction_array = this._get_prediction_array();
      this._compute_prediction_from_model(word, level, total_count);
    }
    this._onResultsComputed();
  }
  _onResultsComputed(){
    if(this._callback){
      this._callback(new PredictionList(this.predictions, this.parsed_input), this._loader);
    }
  }
  _compute_prediction_from_model(word, level, total_count){
    if(this._prediction_array === undefined){
      return;
    }

    if(this._is_my_level(level)) {
      this._compute_next_words(total_count);
    }
    else{
      level--;
      let next_index = this._get_word_index(level);
      let next_word = this.parsed_input.words[next_index];
      let next_word_key = this._loader.getHashKey(next_word);
      let next_word_model = this._get_next_model(this._prediction_array, next_word_key);
      if(next_word_model){
        total_count = next_word_model[INDEX.MODEL.COUNT];
        this._prediction_array = next_word_model[INDEX.MODEL.NEXT_MODEL];
        this._compute_prediction_from_model(next_word, level, total_count);
      }
    }
  }
  _get_next_model(model_data, word_hash_key){
    if(!word_hash_key){
      return undefined;
    }
    let next_word_prediction = _.find(model_data, (item) =>{
      return (item.length >1) && (item[INDEX.MODEL.WORD_HASH] === word_hash_key);
    });
    if( next_word_prediction && next_word_prediction.length > INDEX.MODEL.NEXT_MODEL && Array.isArray(next_word_prediction)){
      return next_word_prediction;  // TODO may be needs a fix
    }
  }
  _get_word_index(level){
    return this.parsed_input.words.length - level;
  }
  _is_my_level(level){
    return level === 1;
  }
  _continueFn(){
    return true;
  }
  _compute_next_words(total_count){
    let prediction_array = this._prediction_array;
    if(!(prediction_array && prediction_array.length > 0)){
      return;
    }

    for(let i= 0; this.predictions.length < this.max_predictions && i < prediction_array.length && prediction_array[i].length > 1; i++){
      if(!this._matches_partially(prediction_array[i])) {
        continue;
      }
      let predictions = this._create_predictions(prediction_array[i], total_count);
      this._compute_next_multi_words(predictions);
    }
  }
  _compute_next_multi_words(predictions){
    _.each(predictions, (prediction) => {
      if(prediction !== undefined){
        this.predictions.push(prediction);
      }
    });
  }
  _get_word_hash(model_item){
    return this._loader.getMapHash(model_item[INDEX.MODEL.WORD_HASH]);
  }

  _matches_partially(prediction){
    if(this.parsed_input.isWholeWord){
      return true;
    }
    let word_hash = this._loader.getMapHash(prediction[INDEX.MODEL.WORD_HASH]);
    // TODO what if not a word hash ?
    if(!word_hash) {
      return false;
    }
    let word = word_hash[INDEX.MAP.WORD];
    return this.parsed_input.comparePartial(word);
  }
  _create_predictions(next_array, total_count){
    let creator = new PredictonCreator({loader:this._loader, model_item:next_array, total_count, parsed_input:this.parsed_input, continueFn:this._continueFn});
    return  creator.getMultiPredictions(undefined, undefined, undefined, this._source);
  }

  _get_prediction_array(){ // returns an array from which predictions are to be calculates
    if(this.parsed_input.isPrevious && this._word_model.length > 1){
      return this._word_model[1];
    }
    else{
      return this._word_model[0];
    }
  }

  _loadInputModel(word, callbackFn){
    let nFileIndex = this._findFileIndex(word);
    this._loader.loadModel(nFileIndex, callbackFn);
  }

  _findFileIndex(word){
    return this._loader.getModelFileNo(word);
  }
}

module.exports = NGramPredictor;
