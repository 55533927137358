let rh = require("../../lib/rh")

rh.model.csubscribe('EVT_EXPAND_COLLAPSE_ALL', () => {
  let currentState = rh.model.cget('ALL_ARE_EXPANDED')
  if(currentState) {
    rh.model.cpublish('EVT_COLLAPSE_ALL')
  } else {
    rh.model.cpublish('EVT_EXPAND_ALL')
  }
})

rh.model.csubscribe('EVT_COLLAPSE_ALL', () => {
  rh.model.cpublish('ALL_ARE_EXPANDED', false)
})

rh.model.csubscribe('EVT_EXPAND_ALL', () => {
  rh.model.cpublish('ALL_ARE_EXPANDED', true)
})
