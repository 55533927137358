const { rh } = window;
const { _ } = rh;
const { $ } = rh;
const { consts } = rh;
const { model } = rh;

const HASH_KEY_RH_SEARCH = consts('HASH_KEY_RH_SEARCH');
const HASH_KEY_TOPIC = consts('HASH_KEY_TOPIC');
const HASH_KEY_UIMODE = consts('HASH_KEY_UIMODE');
const HASH_KEY_RH_TOCID = consts('HASH_KEY_RH_TOCID');
const HASH_KEY_RH_HIGHLIGHT = consts('HASH_KEY_RH_HIGHLIGHT');
const HASH_KEY_RANDOM = consts('HASH_KEY_RANDOM');
const hashQueryKeys = [
  HASH_KEY_RH_HIGHLIGHT,
  consts('HASH_KEY_RH_SYNS'),
  HASH_KEY_RH_TOCID
  ];

// Helper methods
const getMergedParamsMap = function(topicUrl) {
  const paramsMap = _.urlParams(_.extractParamString(topicUrl));
  const hashMap  = _.hashParams(_.extractHashString(topicUrl));
  return _.extend(paramsMap, hashMap);
};

const getTopicURL = function(newMap, topicUrl) {
  topicUrl = topicUrl;
  const filePath = _.filePath(topicUrl);
  let bookMark = _.extractHashString(topicUrl);
  if (bookMark.length > 0) { bookMark = `#${bookMark}`; }

  const paramsMap = _.urlParams(_.extractParamString(topicUrl));
  for (let key of Array.from(hashQueryKeys)) { if (newMap[key] != null) { paramsMap[key] = newMap[key]; } }
  if (model.get(consts('KEY_SCREEN_IOS'))) {
    paramsMap[HASH_KEY_RANDOM] = (_.uniqueId)();
  }
  let params = _.mapToEncodedString(paramsMap);
  if (params.length > 0) { params = `?${params}`; }

  return `${filePath}${params}${bookMark}`;
};

const { queueUpdateHashMap } = _;

const fixHashMapForTopic = function(hashMap) {
  if (hashMap == null) { hashMap = {}; }
  hashMap[HASH_KEY_UIMODE] = null;
  hashMap[HASH_KEY_RANDOM] = null;
  if (!hashMap[HASH_KEY_RH_TOCID]) { hashMap[HASH_KEY_RH_TOCID] = null; }
  if (!hashMap[HASH_KEY_RH_HIGHLIGHT]) { hashMap[HASH_KEY_RH_HIGHLIGHT] = null; }
  return hashMap;
};



const showTopic = function(relUrl, addToHistory) {
  relUrl = _.fixRelativeUrl(relUrl);
  let hashMap = _.urlParams(_.extractParamString(relUrl));
  hashMap[HASH_KEY_TOPIC] = _.stripParam(relUrl);
  hashMap = fixHashMapForTopic(hashMap);
  return queueUpdateHashMap(hashMap, addToHistory);
};


const showNonTopic = function(url, addToHistory) {
  let hashMap = {};
  hashMap[HASH_KEY_TOPIC] =  url;
  hashMap = fixHashMapForTopic(hashMap);
  return queueUpdateHashMap(hashMap, addToHistory);
};

({isSameTopic(url1, url2) {}});

//Event Handlers

const hashChanged = function(obj) {
  let searchTermChanged;
  const { newMap } = obj;
  const { oldMap } = obj;
  const oldUiMode = oldMap[HASH_KEY_UIMODE];
  const uiMode = newMap[HASH_KEY_UIMODE];

  return _.getDefaultTopic(defaultTopic =>
  {
    if (HASH_KEY_RH_SEARCH in newMap)
    {
      const searchTerm = newMap[HASH_KEY_RH_SEARCH];
      searchTermChanged = searchTerm !== model.get(consts('KEY_SEARCHED_TERM'));
      model.publish(consts('KEY_SEARCH_TERM'), searchTerm, { sync: true });
    }

    if (uiMode === 'search')
    {
      if (searchTermChanged && window.gHost)
      {
        model.publish(consts('EVT_QUERY_SEARCH_RESULTS'), true);
      }
    }

    if (!uiMode || newMap[HASH_KEY_TOPIC])
    {
      let topicUrl;
      const oldTopicUrl = model.get(consts('KEY_TOPIC_IN_IFRAME'));
      if (HASH_KEY_TOPIC in newMap) { topicUrl = newMap[HASH_KEY_TOPIC]; }
      if (!topicUrl)
      {
        topicUrl = oldTopicUrl;
      }
      const feature = rh.model.get(rh.consts('KEY_FEATURE'));


      if (!topicUrl && (!feature || feature.showDefTopic !== false))
      {
        topicUrl = defaultTopic;
      }
      const changed = topicUrl && ((topicUrl !== oldTopicUrl) ||
        (oldUiMode !== uiMode) ||
        (oldMap[HASH_KEY_RH_HIGHLIGHT] !== newMap[HASH_KEY_RH_HIGHLIGHT]) ||
        (oldMap[HASH_KEY_RH_TOCID] !== newMap[HASH_KEY_RH_TOCID]));
      if (changed)
      {
        if (_.stripBookmark(topicUrl) !== _.stripBookmark(oldTopicUrl))
        {
          _.runTopicLoadingAnimation(true);
        }
        model.publish(consts('KEY_TOPIC_IN_IFRAME'), topicUrl);
        window.loadTopic(getTopicURL(newMap, topicUrl));
      }
    }

    return model.publish(consts('KEY_UI_MODE'), newMap[HASH_KEY_UIMODE]);

  })
};

const topicChanged = function(topicUrl) {
  const hashMap = _.hashParams();
  if (topicUrl && !hashMap[HASH_KEY_UIMODE]) {
    const decodedURI = decodeURI(document.location.href);
    const relUrl = window._getRelativeFileName(decodedURI, topicUrl);
    const oldUrl = hashMap[HASH_KEY_TOPIC];
    if (!oldUrl || (_.filePath(relUrl) !== _.filePath(oldUrl))) {
      model.publish(consts('KEY_TOPIC_IN_IFRAME'), relUrl);
      return showTopic(relUrl, false);
    }
  }
};

const splitParams = function (params)
{
  let queryParams = {}
  let queryParamList = [consts("RHMAPID"), consts("RHMAPNO"), consts("RHWINDOW"), consts("RHCSHMODE") ]
  _.each(params, (value, key) =>
  { 
    if (queryParamList.includes(key))
    { 
      queryParams[key] = value
      delete params[key]
    } 
  })

  return { queryParams, hashParams: params }
}
const navigateToUrl = function(obj) {
  let url = obj.absUrl;
  if (_.isRootUrl()) {
    if (_.isUrlAllowdInIframe(url)) {
      url = _.makeRelativeUrl(_.fixRelativeUrl(url));
      if(_.isHomeUrl(url)){
        return document.location.href = url
      }
      const fileName = url && _.filePath(url);
      if (fileName === consts('START_FILEPATH')) {
        url = url.substring(fileName.length); //never pass start file inside iframe
      } else if (!url) {
        url = '#ux';
      }

      if ((url[0] === '#') || (url[0] === '?')) {
        const hashMap = _.hashParams(_.extractHashString(url));
        if (!(HASH_KEY_UIMODE in hashMap)) { hashMap[HASH_KEY_UIMODE] = null; }
        return queueUpdateHashMap(hashMap, true);
      } else if (url) {
        return showTopic(url, true);
      }
    } else {
      return showNonTopic(url, true);
    }
  } else {
    if (!_.isRootUrl(url)) {
      let params, relUrl;
      const rootUrl = _.getRootUrl();
      if (_.isExternalUrl(url)) {
        relUrl = url;
      } else
      {
        relUrl = _.fixRelativeUrl(_.makeRelativePath(url, rootUrl));
        params = _.urlParams(_.extractParamString(relUrl));
        params[HASH_KEY_TOPIC] = _.stripParam(relUrl);
        params = fixHashMapForTopic(params);
        let { queryParams, hashParams } = splitParams(params)
        url = `${rootUrl}?${_.mapToEncodedString(queryParams)}#${_.mapToEncodedString(hashParams)}`;
      }
    }
    return document.location.href = url;
  }
};

model.subscribe(consts('EVT_WIDGET_LOADED'), function() {
  _.addEventListener(document, 'click', _.hookClick);

  model.subscribe(consts('EVT_HASH_CHANGE'), hashChanged);

  model.subscribe(consts('KEY_TOPIC_URL'), topicChanged);

  model.subscribe(consts('EVT_NAVIGATE_TO_URL'), navigateToUrl);

  // call to update old layout specific variables
  model.subscribe(consts('KEY_TOPIC_IN_IFRAME'), function(topicUrl) {
    if (`${window.gHost}${window.gHostPath}` !== '/') { // wait to resolve gHostPath
      if (window.setTopic) { (window.setTopic)(); }
      if (window.changeTopicLink) {
        return window.changeTopicLink(document.location.toString());
      }
    }
  });

  model.subscribe(consts('EVT_TOPIC_LOADED'), () => _.runTopicLoadingAnimation(false));

  return model.subscribe(consts('EVT_INSIDE_IFRAME_DOM_CONTENTLOADED'), () => _.runTopicLoadingAnimation(false));
});
