

const INDEX = require('./indices');

let _exports = {
  getNextModel: (model_item) =>{

    if( model_item  && Array.isArray(model_item) && model_item.length > INDEX.MODEL.NEXT_MODEL){
      return model_item[INDEX.MODEL.NEXT_MODEL];
    }
  },
  getFirstLevelNextModel: (model_item, bprevious) => {
    if(model_item && Array.isArray(model_item)){
      if( !bprevious && model_item.length > INDEX.FIRST_PREDICTION.NEXT){
        return model_item[INDEX.FIRST_PREDICTION.NEXT];
      }
      if( bprevious && model_item.length > INDEX.FIRST_PREDICTION.PREVIOUS ){
        return model_item[INDEX.FIRST_PREDICTION.PREVIOUS];
      }
    }
  },

  getFirstPrediction: (model_item)=> {
    if(model_item && Array.isArray(model_item) && model_item.length > 0 && Array.isArray(model_item[0])){
      return model_item[0];
    }
  },
  compareNoCase(word1, word2){
    if(global.compare !== undefined){
      return global.compare(word1, word2);
    }
    else{
      return (word1 === word2) ? 0 : -1;
    }
  },
  hammingDistance(word1, word2){
    let minLength = Math.min(word1.length, word2.length);
    let hammingDistance = 0;
    for(let i=0; i<minLength; i++){
      if(word1[i] !== word2[i]) {
        hammingDistance++;
      }
    }
    if(word1.length > minLength) {
      hammingDistance+= word1.length - minLength;
    }
    return hammingDistance;
  },
  isPrevious(keyEvt){
    return (keyEvt.selStart < keyEvt.text.length)
  },
  shouldShowSuggestion(keyEvt){
    let text = keyEvt.text
    let selStart = keyEvt.selStart
    if(selStart === 0 || selStart === text.length){
      return true
    }
    if(text.length > 0 ){ // if cursor is before the end
      return text[selStart] === ' ' || text[selStart-1] === ' '
    }
    return false;
  },
  shouldAppendSpace(keyEvt){
    let text = keyEvt.text
    let selStart = keyEvt.selStart
    if (text.length > 0 && selStart === text.length){//cusror at last
      return text[text.length -1] !== " ";
    }
    return false;
  },

  getSafeElement(arr, index){
    if(arr && Array.isArray(arr) && arr.length > index) {
      return arr[index];
    }
  },
  getCountfromModel(model_item){
    if(Array.isArray(model_item) && model_item.length > INDEX.MODEL.COUNT) {
      return model_item[INDEX.MODEL.COUNT];
    }
    return 0;
  },
  getCountfromWordHash(word_hash){
    if(Array.isArray(word_hash) && word_hash.length > INDEX.MAP.COUNT) {
      return word_hash[INDEX.MAP.COUNT];
    }
    return 0;
  }
};

module.exports = _exports;
