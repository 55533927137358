let rh = require("../../../../lib/rh")
let _ = rh._;
let Prediction = require('./prediction');
let Parser = require('./input_parser');
let PredictionList = require('./prediction_list');
const search_consts = require('./consts');

class HistoryPredictor{
  constructor(reader, callback){
    this._reader = reader;
    this._predictions = [];
    this._callback = callback;
    this._source = search_consts.PREDICTOR_SOURCE_ID.HISTORY_PREDICTOR;
    this._max_predictions = (search_consts._max_history_predictions === undefined)? 2: search_consts._max_history_predictions;
  }
  getPredictions(parsed_input) {
    this._predictions = [];
    this._parsed_input = parsed_input;
    this._computePredictions();
    if(this._callback){
      this._callback(new PredictionList(this._predictions, this._parsed_input));
    }
    return this._predictions;
  }
  init(){
  }
  clear(){
    this._predictions = [];
  }
  _computePredictions(){
    if(this._parsed_input.trimmedText !== ""){
      _.each(this._reader._list, (item)=>{
        if(this._match(item.text)){
          this._predictions.push(this._createPrediction(item));
        }
      });
      this._purge();
    }
  }
  _purge(){
    this._predictions.splice(this._max_predictions);
  }
  _createPrediction(item){
    let newPrediction = new Prediction(item.text, 1, item.count, this._source);
    return newPrediction;
  }
  _match(text){
    if(this._parsed_input.original_text.length > text.length) {
      return false
    }
    let parsed_text = new Parser(text, true);
    return this._parsed_input.compare(parsed_text);
  }
}

module.exports = HistoryPredictor;
