let rh = require("../../lib/rh")
let _ = rh._
class HomeController {

  constructor(widget) {
    this.widget = widget
  }

  goToHome(tocId) {
    if(rh.consts('HOME_FILEPATH') === rh._.getFileName()) {
      return this.changeToDefaultTopic(tocId)
    }
    let hashMap
    if(tocId !== undefined && tocId !==''){
      hashMap = {rhtocid:tocId}
    }
    _.goToHome(hashMap);
    return true
  }

  changeToDefaultTopic(tocId) {
    let hashMap = {t: window.gDefaultTopic.substring(1)}
    if(tocId !== undefined && tocId !==''){
      hashMap.rhtocid = tocId
    }
    _.queueUpdateHashMap(hashMap, true)
    return true
  }
}

rh.controller('HomeController', HomeController)
