let rh = require("../../../lib/rh")
let $ = rh.$;
let CAROUSEL_CHANGE = 'change';
let CAROUSEL_ITEM = '.carousel-item:not(.hide):not(.rh-hide)';

class Carousel {
  constructor(widget, opts) {
    this.widget = widget;
    this.node = this.widget.node;
    this.tab = 0;
    rh.model.subscribeOnce(rh.consts(opts.key), () => {
      this.widget.publish(CAROUSEL_CHANGE, true);
    });
    rh.model.subscribe(rh.consts('KEY_SCREEN'), () => {
      this.goto(this.tab, 0);
    });
    rh.model.subscribe(opts.key+'gototab', (bookData) => {
      this.goto(this.pagecount(), bookData.tab);
    });
  }

  goto(from, to) {
    this.tab = to;
    this.carouselnode = $.find(this.node, '.carousel')[0];
    this.animateLeft(from);
    rh._.delay(() => {
      this.widget.publish(CAROUSEL_CHANGE, true);
    }, 1000);
  }

  hasNext() {
    this.carouselnode = $.find(this.node, '.carousel')[0];
    let lastItem = $.find($.find(this.node, '.carousel')[0], CAROUSEL_ITEM);
    lastItem = (lastItem && lastItem.length > 0)? lastItem[lastItem.length - 1] : null;
    return lastItem.offsetLeft + this.carouselnode.offsetLeft > this.carouselnode.offsetWidth;
  }

  animateLeft() {
    let leftVal = 'calc(-' + this.tab*14 + 'px - ' + this.tab*100 + '%)'
    $.css(this.carouselnode, 'left', leftVal)
  }

  next() {
    this.goto(this.tab, this.tab+1);
  }

  pagecount() {
    return this.tab;
  }

  previous() {
    this.goto(this.tab, this.tab-1);
  }

  ensureVisible() {

  }
}

rh.controller('Carousel', Carousel);
