import search_utils from '../suggestion/utils'
module.exports = class SearchResult{
  constructor(result) {
    this.fRanking = result.score
    this.nIndex = 1
    this.strSummary = `.p.searchsummary${result.ref}`
    this.strTitle = result.title
    this.strUrl = `.p.searchurl${result.ref}`
    this.strBreadcrumbs = result.relUrl
  }
  merge(new_item) {
    this.fRanking += new_item.fRanking;
  }
  compare(other) {
    return other.fRanking - this.fRanking
  }
  match(other) {
    return search_utils.compareNoCase(other.strUrl, this.strUrl) === 0;
  }
}