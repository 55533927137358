let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

rh.model.subscribe(consts('EVT_WIDGET_BEFORELOAD'), function() {
  if ($('a.wShow', 0) !== null) {
    let contentDiv = $('.contenttopic', 0);
    $.setAttribute(contentDiv, 'data-rhwidget', 'Basic');
    return $.setAttribute(contentDiv, 'data-class',
      'govt_csh:@.l.csh_mode;govt_nocsh:!@.l.csh_mode');
  }
});