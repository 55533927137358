let rh = require("../../lib/rh")
let $ = rh.$;
let _ = rh._;
let consts = rh.consts;
class RH2017LayoutController {

  constructor(widget) {
    this.widget = widget;
    this.topicFrame = '.topic-container iframe';
    this.initLayout();
    this.initModal();
  }

  isLayoutMode(hashMap) {
    let hash = hashMap || _.hashParams();
    let topicMode = hash[rh.consts('HASH_KEY_TOPIC')];
    return (hash[rh.consts('HASH_KEY_UIMODE')] !== consts('HELP_SEARCH_MODE') && (!topicMode ||
        (hash[rh.consts('HASH_HOMEPAGE_MODE')] === 'true') ||
         hash[rh.consts('HASH_KEY_RH_TOCID')]));
  }

  initLayout() {
    rh.model.subscribe(consts('EVT_HASH_CHANGE'), (obj) => {
      let hashMap = obj.newMap, oldHashMap = obj.oldMap;
      if(this.isLayoutMode()) {
        this.widget.publish(rh.consts('KEY_VIEW_MODE'), rh.consts('HELP_LAYOUT_MODE'));
      }
      else if(hashMap[rh.consts('HASH_KEY_UIMODE')] === 'search'){
        this.widget.publish(rh.consts('KEY_VIEW_MODE'), rh.consts('HELP_SEARCH_MODE'));
        _.queueUpdateHashMap({[consts('HASH_HOMEPAGE_MODE')]: false}, false);
      }
      else {
        this.widget.publish(rh.consts('KEY_VIEW_MODE'), rh.consts('HELP_TOPIC_MODE'));
      }

      var feature = rh.model.get(rh.consts('KEY_FEATURE'))
      if(feature && feature.showDefTopic === false &&
        !hashMap[rh.consts('HASH_KEY_RH_TOCID')] &&
        !oldHashMap[rh.consts('HASH_KEY_TOPIC')] &&
        hashMap[rh.consts('HASH_KEY_TOPIC')]) {
        _.queueUpdateHashMap({[consts('HASH_HOMEPAGE_MODE')]: 'false'}, false)
      }
      else if(oldHashMap[rh.consts('HASH_KEY_TOPIC')] &&
        !hashMap[rh.consts('HASH_KEY_RH_TOCID')] &&
        oldHashMap[rh.consts('HASH_KEY_TOPIC')] !== hashMap[rh.consts('HASH_KEY_TOPIC')] &&
        hashMap[rh.consts('HASH_HOMEPAGE_MODE')] === 'true' &&
        oldHashMap[rh.consts('HASH_HOMEPAGE_MODE')] === hashMap[rh.consts('HASH_HOMEPAGE_MODE')]) {
        _.queueUpdateHashMap({[consts('HASH_HOMEPAGE_MODE')]: 'false'}, false);
      }
    });

    let hashMap = _.hashParams();
    if(this.isLayoutMode() &&
        hashMap[rh.consts('HASH_HOMEPAGE_MODE')] !== 'true')
    {
      _.queueUpdateHashMap({[consts('HASH_HOMEPAGE_MODE')]: true}, false);
    }
    rh.model.subscribe('EVT_TOC_LOADEDgototab', (bookData) => {
      if(bookData.key) {
        rh.model.subscribe(bookData.key, (bookTOC, key, unsub) => {
          unsub();
          if(bookData.item && bookData.item.url && !bookTOC[0].added) {
            bookTOC.unshift({'type': 'item', 'url': bookData.item.url,
              'name': bookData.item.name, 'added': true});
            rh.model.publish(bookData.key, bookTOC);
          }
        });
      }
      if(bookData.item.url) {
        this.viewTopicInLayoutMode(bookData.item.url);
      }
    });
  }

  viewTopicInLayoutMode(topicUrl) {
    let paramMap = {'homepage': 'true',
      [rh.consts('HASH_KEY_RH_TOCID')]: true}
    if(topicUrl && topicUrl !== '#') {
      paramMap.t = topicUrl
    }
    _.queueUpdateHashMap(paramMap, false)
  }

  initModal() {
    let bHandled = false
    document.addEventListener("keydown", e => {
      if ( e.keyCode === 27 ) { // ESC
        rh._.each($.find('.modal'), item => {
          $.addClass(item, 'rh-hide');
        });
      }
    }, false);
    document.addEventListener('click', function () {
      if(!bHandled) {
        rh._.each($.find('.modal'), function (item) {
          $.addClass(item, 'rh-hide');
        });
      }
      bHandled = false
    });
    rh._.each($.find('.modal-content'), function (item) {
      _.addEventListener(item, 'click', function() {
        bHandled = true
      })
    });
  }
}

rh.controller('RH2017LayoutController', RH2017LayoutController)
