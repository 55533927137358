let { rh } = window;
let { _ } = rh;
let { consts } = rh;
let { model } = rh;
let unsub = null;

unsub = model.subscribe(consts('KEY_TAG_EXPRESSION'), (function() {
  let done = false;
  return function() {
    if (unsub) { unsub(); }
    if (done) { return; }
    done = true;
    return model.subscribe(consts('KEY_SEARCHED_TERM'), () =>
      model.publish(consts('KEY_ONSEARCH_TAG_EXPR'),
       JSON.stringify(model.get(consts('KEY_TAG_EXPRESSION'))))
    );
  };
})()
);

model.subscribe(consts('EVT_SEARCH_TERM'), function() {
  let canHandleSearch = model.get(consts('KEY_CAN_HANDLE_SEARCH'));
  let searchTerm = model.get(consts('KEY_SEARCH_TERM')) || null;
  let newMap = {};
  newMap[consts('HASH_KEY_RH_SEARCH')] = searchTerm;
  newMap[consts('HASH_KEY_UIMODE')] = 'search';
  model.publish(consts('DESKTOP_SIDEBAR_VISIBLE'), true);
  let searchResultInTabbar = model.get(consts('KEY_DEFAULT_SEARCH_LOCATION')) === 'tabbar'
  if (searchResultInTabbar) {
    model.publish(consts('KEY_ACTIVE_TAB'), 'fts');
  }
  
  let hlTerm = _.urlParam(consts('HASH_KEY_RH_HIGHLIGHT'), document.location.href)
  if (searchTerm !== hlTerm) {
    newMap[consts('HASH_KEY_RH_HIGHLIGHT')] = ''
    }
  if (canHandleSearch !== true) {
    return document.location = `${window.gRootRelPath}/` +
      `${window.gSearchPageFilePath || consts('START_FILEPATH')}` +
      `?#${_.mapToEncodedString(newMap)}`;
  } else {
    model.publish(consts('EVT_QUERY_SEARCH_RESULTS'), true);
    return _.defer(() => _.updateHashMap(newMap, true));
  }
});

model.subscribe(consts('EVT_PROJECT_LOADED'), function() {
  if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
    window.readSetting(RHANDSEARCH, function(state) {
      return model.publish(consts('KEY_AND_SEARCH'), state);
    });
  }
  return model.subscribe(consts('KEY_AND_SEARCH'), function(state) {
    if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
      return saveSetting(RHANDSEARCH, (state === '1' ? '1' : '0'), true);
    }
  });
});
model.subscribe(consts('EVT_QUERY_SEARCH_RESULTS'), function() {
  if(rh.model.get(rh.consts('KEY_CLASSIC_OUTPUT'))) {
    return window.doSearch();
  }
});
