class PredictionList {
  constructor(list , parsed_input){
    this.parsed_input = parsed_input;
    this._list = list || [];
  }
  get predictions (){
    return this._list;
  }
  isForInput(input, previous_input){
    if(this.parsed_input.isPrevious){
      return (previous_input !== undefined) && (this.parsed_input.original_text === previous_input.original_text);
    }
    else{
      return this.parsed_input.original_text === input.original_text;
    }
  }
  isEmpty(){
    return this._list.length === 0;
  }
}

module.exports = PredictionList;