let { rh } = window;
let { _ } = rh;
let { $ } = rh;

class BrowseSequenceBuilder {

  constructor() {

    let handleTopicChange = function(doNotUse) {
      let brsMap = rh.model.get('t.brsmap');
      if (brsMap != null) {
        let next = (brsMap['n'] && brsMap['n'][0]) || '';
        let prev = (brsMap['p'] && brsMap['p'][0]) || '';
        let decodedURI = window.decodeURI(document.location.href);

        if (!_.isEmptyString(next)) {
          next = window._getRelativeFileName(decodedURI, next);
        }
        if (!_.isEmptyString) {
          prev = window._getRelativeFileName(decodedURI, prev);
        }

        rh.model.publish('l.brsBack', prev);
        return rh.model.publish('l.brsNext', next);
      }
    };

    let updateShowBrs = function() {
      let uiMode = rh.model.get(rh.consts('KEY_UI_MODE'));
      let prev = rh.model.get('l.brsBack');
      let next = rh.model.get('l.brsNext');
      let showBrs = !uiMode && (prev || next) ? true : false;
      return rh.model.publish('l.show_brs', showBrs);
    };

    rh.model.subscribe('t.topicurl', _.debounce(handleTopicChange, 200));
    rh.model.subscribe('t.brsmap', _.debounce(handleTopicChange, 200));

    rh.model.subscribe('l.brsBack', updateShowBrs);
    rh.model.subscribe('l.brsNext', updateShowBrs);
    rh.model.subscribe(rh.consts('KEY_UI_MODE'),  updateShowBrs);
  }
}

//TODO: move it to controller
rh.widgets.BrowseSequenceBuilder = BrowseSequenceBuilder;
