const PredictionList = require('./prediction_list');
const PredictonCreator = require('./prediction_creator');
const search_consts = require('./consts');
const INDEX = require('./indices');
const rh = require("../../../../lib/rh")
const _ = rh._;
const PredictonMerger = require('../common/merger');

class GeneralPredictor{
  constructor(callback, loader, max_predictions = 20){
    this._max_predictions = max_predictions;
    this._loader = loader;
    this.total_count = this._loader.getTotalCount();
    this._callback = callback;
    this._source = search_consts.PREDICTOR_SOURCE_ID.GENERAL_PREDICTOR;
    this.prediction_merger = new PredictonMerger([], this.total_count*5);
  }
  clear(){
    this.prediction_merger.clear();
    this.parsed_input = undefined
  }
  getPredictions(parsed_input){

    this._init(parsed_input);
    this._computePredictions()

    this._onResultComputed();
  }
  _computePredictions(){

    let word_hashes = this._getWordHashes();
    if(word_hashes){
      this._addPredictions(word_hashes);
    }
  }
  _isWholeWordInput(){
    return this.parsed_input.isWholeWord;
  }
  _getWordHashes(){
    return (this.parsed_input.isWholeWord)?
             this._loader.top_words
             :this._loader.getHashes(this.parsed_input._partial)
  }
  _init(parsed_input){
    this.prediction_merger = new PredictonMerger([], this.total_count*5);
    this.parsed_input = parsed_input;
  }
  get predictions (){
    return this.prediction_merger.items
  }
  _onResultComputed(){
    if(this._callback){
      this._callback (new PredictionList(this.predictions, this.parsed_input), this._loader);
    }
  }

  set max_predictions(newValue){
    this._max_predictions = newValue;
  }
  get max_predictions(){
    return this._max_predictions;
  }
  _addPredictions(hashes){
    if(!hashes) {
      return;
    }

    let hash_count = hashes.length;
    for(let i = 0; i < hash_count && this.predictions.length < this.max_predictions; i++){
      let hash = hashes[i];
      this._addWordPrediction(hash);
    }
  }
  _addWordPrediction(word_hash, matchFn){
    matchFn = matchFn || this._matches_partially.bind(this);
    let model_data = this._loader.getModelData(word_hash);
    let total_count = this._loader.total_count;
    if(matchFn(word_hash)){
      let word = word_hash[INDEX.MAP.WORD];
      if(this._should_add_prediction(word)){
        this._create_predictions(word_hash, total_count, model_data);
      }
    }
  }

  _continueFn(){
    return true;
  }
  _should_add_prediction(word){
    return !this._loader.isStopWord(word) && !this.parsed_input.equalLastWord(word)
  }
  _matches_partially(word_hash){
    if(this.parsed_input.isWholeWord){
      return true;
    }

    let word = word_hash[INDEX.MAP.WORD];
    return this.parsed_input.comparePartial(word);
  }
  _create_predictions(word_hash, model_count, model_item){
    let creator = new PredictonCreator({loader: this._loader, model_item, total_count:model_count, parsed_input:this.parsed_input, continueFn: this._continueFn});
    let predictions = creator.getMultiPredictions(word_hash, true, undefined, this._source);
    _.each(predictions, (prediction) => {
      if(prediction !== undefined){
        this.prediction_merger.mergeItem(prediction)
      }
    });
  }

  _calculate_probability(count){
    return (count/this.total_count);
  }
}

module.exports = GeneralPredictor;
