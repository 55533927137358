var ModernLayoutController = (function() {
  let rh = undefined;
  let _ = undefined;
  let $ = undefined;
  let consts = undefined;
  let KEY_FEATURE = undefined;
  let KEY_FILTER_LOCATION = undefined;
  let KEY_SEARCH_LOCATION = undefined;
  let KEY_ACTIVE_TAB = undefined;
  let KEY_UI_MODE = undefined;
  let KEY_MOBILE_TOC_DRILL_DOWN = undefined;
  let KEY_TOC_DRILL_DOWN = undefined;
  let DESKTOP = undefined;
  let TABLET = undefined;
  let PHONE = undefined;
  let SHOW_FILTER_UI = undefined;
  let tabs = undefined;
  let sideBarTabs = undefined;
  let delayLoadTabs = undefined;
  ModernLayoutController = class ModernLayoutController {
    static initClass() {

      ({ rh } = window);
      ({ _ } = rh);
      ({ $ } = rh);
      ({ consts } = rh);
      KEY_FEATURE = consts('KEY_FEATURE');
      KEY_FILTER_LOCATION = consts('KEY_FILTER_LOCATION');
      KEY_SEARCH_LOCATION = consts('KEY_SEARCH_LOCATION');
      KEY_ACTIVE_TAB = consts('KEY_ACTIVE_TAB');
      KEY_UI_MODE = consts('KEY_UI_MODE');
      KEY_MOBILE_TOC_DRILL_DOWN = consts('KEY_MOBILE_TOC_DRILL_DOWN');
      KEY_TOC_DRILL_DOWN = consts('KEY_TOC_DRILL_DOWN');
      DESKTOP = 'desktop';
      TABLET = 'tablet';
      PHONE = 'phone';
      SHOW_FILTER_UI = rh._params.hideUI !== 'filter';
      tabs = {
        desktop: ['toc', 'idx', 'glo', 'filter', 'fts'],
        tablet: ['toc', 'idx', 'glo', 'filter', 'fts'],
        phone: ['toc', 'idx', 'glo', 'filter', 'fts']
      };

      sideBarTabs = {
        desktop: ['toc', 'idx', 'glo', 'filter', 'fts'],
        tablet: ['toc', 'idx', 'glo', 'filter', 'fts'],
        phone: ['toc', 'idx', 'glo']
      };
      delayLoadTabs = {
        'idx': {evt: 'EVT_LOAD_IDX', f_key: 'delay_load_idx'},
        'glo': {evt: 'EVT_LOAD_GLO', f_key: 'delay_load_glo'}
      };
    }

    constructor(widget) {
      this.triggerDelayLoadWidgets = this.triggerDelayLoadWidgets.bind(this);
      this.widget = widget;
      (this.init)();
      (this.subscribeActiveTab)();
      (this.subscribeToLocations)();
      (this.subscribeScreens)();

      this.widget.subscribe(consts('EVT_PROJECT_LOADED'), () => {
        (this.subscribeUIMode)();
        (this.subscribeMergedFilter)();
        (this.subscribeTopicClick)();
        (this.subscribeTopicScroll)();
        (this.subscribForSearchResult)();
        (this.subscribeTopicNavigate)();
        (this.subscribeToDelayLoadEvents)();
        return this.widget.publish(consts('KEY_IFRAME_EVENTS'), {click: true, scroll: false});
      });
    }

    init() {
      this.screens = this.widget.get(consts('KEY_SCREEN_NAMES'));
      rh.storage.init(consts('HELP_ID'));
      this.activeScreen = _.find(this.screens, function(screen) {
        return this.widget.get(`${consts('KEY_SCREEN')}.${screen}.attached`);
      }
      , this);
      if (this.activeScreen == null) { this.activeScreen = this.widget.get(consts('KEY_DEFAULT_SCREEN')); }

      this.assureLocations(this.activeScreen);

      (this.initDefauls)();

      return (this.initActiveTab)();
    }

    initDefauls() {
      let defaultTab = this.widget.get(consts('KEY_DEFAULT_TAB'));
      if (!this.hasFeature(defaultTab)) { defaultTab = null; }

      this.defaultTabs = {};

      return _.each(this.screens, function(screen) {
        tabs[screen] = _.filter(tabs[screen], (function(tab) { return this.hasFeature(tab); }), this);

        sideBarTabs[screen] = _.filter(sideBarTabs[screen], function(tab) {
          return this.hasFeature(tab);
        }
        , this);

        return this.defaultTabs[screen] =
          defaultTab && (-1 !== tabs[screen].indexOf(defaultTab)) ?
            defaultTab
          :
            tabs[screen][0];
      }
      , this);
    }

    initActiveTab() {
      let activeTab = rh.storage.fetch('activetab');
      if ((activeTab !== undefined) && activeTab && this.hasFeature(activeTab)) {
        return this.widget.publish(KEY_ACTIVE_TAB, activeTab);
      }
    }

    isValidTab(tab, screen) {
      if (screen == null) { screen = this.activeScreen; }
      if (!tab || !this.hasFeature(tab)) { return false; }

      if (screen === DESKTOP) {
        if (((tab === 'filter') && ('content' === this.widget.get(KEY_FILTER_LOCATION))) ||
        ((tab === 'fts') && ('content' === this.widget.get(KEY_SEARCH_LOCATION)))) {
          return false;
        }
      }

      return -1 !== tabs[screen].indexOf(tab);
    }

    defaultTab(screen) {
      if (screen == null) { screen = this.activeScreen; }
      let defaultTab = this.defaultTabs[screen];
      if (!this.isValidTab(defaultTab)) {
        defaultTab = _.find(tabs[screen], (function(tab) { return this.isValidTab(tab); }), this);
      }
      return defaultTab;
    }

    assureValidActiveTab(screen) {
      if (screen == null) { screen = this.activeScreen; }
      let activetab = this.widget.get(KEY_ACTIVE_TAB);
      if ((screen === DESKTOP) || activetab) {
        if (!this.isValidTab(activetab)) { activetab = this.defaultTab(); }
        return this.widget.publish(KEY_ACTIVE_TAB, activetab);
      }
    }

    toggleSideBar() {
      if (this.widget.get(KEY_ACTIVE_TAB)) {
        return this.widget.publish(KEY_ACTIVE_TAB, null);
      } else {
        return this.widget.publish(KEY_ACTIVE_TAB, this.lastMobileTab || this.defaultTab());
      }
    }

    toggleActiveTab(tab) {
      return this.widget.publish(KEY_ACTIVE_TAB,
        !this.isDesktopScreen() && (tab === this.widget.get(KEY_ACTIVE_TAB)) ? null
        : tab
      );
    }

    triggerDelayLoadWidgets(tab) {
      let tabObj = delayLoadTabs[tab];
      if (tabObj && this.hasFeature(tabObj.f_key)) {
        return this.widget.publish(consts(tabObj.evt), true);
      }
    }

    subscribeToDelayLoadEvents() {
      return this.widget.subscribe(KEY_ACTIVE_TAB, this.triggerDelayLoadWidgets);
    }

    filterDone() {
      return this.widget.publish(KEY_ACTIVE_TAB, this.searchMode ? 'fts' : null);
    }

    hasFeature(feature) {
      return (feature === undefined) || (false !== this.widget.get(`${KEY_FEATURE}.${feature}`));
    }

    subscribeUIMode() {
      let setSearchTab = () => {
        if (!this.isValidTab('fts')) { return; }
        if (!this.isDesktopScreen() || ('tabbar' === this.widget.get(KEY_SEARCH_LOCATION))) {
          return this.widget.publish(KEY_ACTIVE_TAB, 'fts');
        }
      };

      this.widget.subscribe(KEY_UI_MODE, mode => {
        return this.widget.publish('active_content', mode);
      });

      this.widget.subscribe('active_content', mode => {
        if (this.isMobileScreen() && !mode) {
          return this.widget.publish(KEY_ACTIVE_TAB, null);
        } else if ('search' === mode) {
          return setSearchTab();
        }
      }
      , {initDone: true});
      if ('search' === this.widget.get('active_content')) { return setSearchTab(); }
    }

    subscribeMergedFilter() {
      let featureFilterKey;
      if (this.widget.get(featureFilterKey = `${KEY_FEATURE}.filter`)) {
        return this.widget.subscribe(consts('KEY_MERGED_FILTER_KEY'), tags => {
          this.widget.publish(featureFilterKey,
            tags && tags.length && SHOW_FILTER_UI ? true : false);
          return (this.assureValidActiveTab)();
        });
      }
    }

    subscribeTopicClick() {
      return this.widget.subscribe(consts('EVT_CLICK_INSIDE_IFRAME'), () => {
        if ((this.activeScreen === PHONE) && this.widget.get(KEY_ACTIVE_TAB)) {
          return (this.toggleSideBar)();
        }
      });
    }

    subscribeTopicNavigate() {
      return this.widget.subscribe(consts('EVT_NAVIGATE_TO_URL'), obj => {
        if (obj.absUrl && _.isUrlAllowdInIframe(obj.absUrl)) {
          return this.widget.publish('active_content', null);
        }
      });
    }

    subscribeTopicScroll() {
      return this.widget.subscribe(consts('EVT_SCROLL_INSIDE_IFRAME'), info => {
        let hide_header = (info.dir === 'down') || (info.scrollTop > 10);
        this.widget.publish('hide_header', hide_header);

        let hide_mobile_functions = (info.dir === 'down') && (info.scrollTop > 10);
        return this.widget.publish('hide_mobile_functions', hide_mobile_functions);
      });
    }

    subscribeActiveTab() {
      return this.widget.subscribe(KEY_ACTIVE_TAB, activeTab => {
        rh.storage.persist('activetab', activeTab);
        this.setFocusInTab(activeTab);
        if (this.isMobileScreen()) {
          if (this.isSidebarTab(activeTab)) { this.lastMobileTab = activeTab; }
          return this.searchMode =
            (activeTab === 'fts') || (this.searchMode && (activeTab === 'filter'));
        } else {
          return this.searchMode = false;
        }
      });
    }

    setFocusInTab(tab) {
      var className
      switch (tab) {
      case 'fts':
        className = 'wSearchField'
        break;
      case 'idx':
        className = 'IdxFilter'
        break;
      case 'glo':
        className = 'GloFilter'
        break;

      default:
        return;
      }
      var inputElement = document.getElementsByClassName(className)
      if (inputElement && inputElement.length) {
        _.defer(function () { inputElement[0].focus() });
      }
    }

    subscribeScreens() {
      return _.each(this.screens, function(screen) {
        let key = `${consts('KEY_SCREEN')}.${screen}.attached`;
        if (this.widget.get(key)) { this.handleScreen(true, screen); }
        return this.widget.subscribe(key, attached => {
          return this.handleScreen(attached, screen);
        }
        , {initDone: true});
      }
      , this);
    }

    subscribeToLocations() {
      this.widget.subscribe(KEY_SEARCH_LOCATION, () => {
        return this.assureValidActiveTab(this.activeScreen);
      });

      return this.widget.subscribe(KEY_FILTER_LOCATION, () => {
        return this.assureValidActiveTab(this.activeScreen);
      });
    }

    subscribForSearchResult() {
      let updateSearchResults = () => {
        let tagExpr = this.widget.get(consts('KEY_TAG_EXPRESSION'));
        let searchTagExpr = this.widget.get(consts('KEY_ONSEARCH_TAG_EXPR'));
        if (searchTagExpr && (searchTagExpr !== JSON.stringify(tagExpr))) {
          return this.widget.publish(consts('EVT_SEARCH_TERM'), true);
        }
      };

      this.widget.subscribe(consts('KEY_TAG_EXPRESSION'), _.debounce(() => {
        if (this.isValidTab('fts') && ('fts' !== this.widget.get(KEY_ACTIVE_TAB))) { return; }
        if (!this.isSearchMode(this.widget.get(KEY_ACTIVE_TAB))) { return; }

        return updateSearchResults();
      }
      , 300)
      );

      return this.widget.subscribe(KEY_ACTIVE_TAB, function(tab) {
        if (tab === 'fts') { return updateSearchResults(); }
      });
    }

    isMobileScreen() { return this.activeScreen === PHONE; }

    isDesktopScreen() { return this.activeScreen === DESKTOP; }

    isTabletScreen() { return this.activeScreen === TABLET; }

    isSidebarTab(tab) {
      return tab && (-1 !== sideBarTabs[this.activeScreen].indexOf(tab));
    }

    isSearchMode(tab) {
      let topicTab = this.widget.get('active_content');
      return (tab === 'fts') || ((topicTab === 'search') && this.isDesktopScreen() &&
      ('content' === this.widget.get(KEY_SEARCH_LOCATION)));
    }

    handleScreen(attached, screen) {
      if (attached) {
        this.activeScreen = screen;
        return this.handleAttached(screen);
      } else {
        return this.handleDetached(screen);
      }
    }

    handleDetached(screen) { return _.defer(() => {
      if (screen === DESKTOP) {
        return this.widget.publish(KEY_ACTIVE_TAB,
          ('search' === this.widget.get('active_content')) && this.isValidTab('fts') ?
            'fts'
          : null
        );
      }
    }); }

    handleAttached(screen) {
      this.assureLocations(screen);
      this.assureValidActiveTab(screen);
      return this.assureTOCMode(screen);
    }

    assureLocations(screen) {
      if (screen == null) { screen = this.activeScreen; }
      this.widget.publish(KEY_SEARCH_LOCATION,
        screen === DESKTOP ?
          this.widget.get(consts('KEY_DEFAULT_SEARCH_LOCATION'))
        : screen === TABLET ?
          'tabbar'
        :
          'content'
      );
      return this.widget.publish(KEY_FILTER_LOCATION,
        (screen === DESKTOP) || (screen === TABLET) ? 'tabbar' : 'content');
    }

    assureTOCMode(screen) {
      if (screen == null) { screen = this.activeScreen; }
      return this.widget.publish(KEY_TOC_DRILL_DOWN,
        (screen !== DESKTOP) && this.widget.get(KEY_MOBILE_TOC_DRILL_DOWN));
    }

    newSearch(text, keyCode, event) {
      return
      if (keyCode === 13) {
        let oldText = this.widget.get(consts('KEY_SEARCH_TERM'));
        if (oldText !== text) { this.widget.publish(consts('KEY_SEARCH_TERM'), text); }
        this.widget.publish(consts('EVT_SEARCH_TERM'), true);
        if (event != null ? event.target : undefined) { return (event.target.blur)(); }
      }
    }

    isTagStatesChanged() {
      let currentState = _.compactObject(this.widget.get(consts('KEY_PROJECT_TAG_STATES')));
      let defaultState = _.compactObject(this.widget.get(consts('KEY_DEFAULT_FILTER')));
      return !_.isEqual(currentState, defaultState);
    }

    setDefaultTagStates() {
      let defaultState = this.widget.get(consts('KEY_DEFAULT_FILTER'));
      return this.widget.publish(consts('KEY_PROJECT_TAG_STATES'), _.clone(defaultState));
    }
  };
  ModernLayoutController.initClass();
  return ModernLayoutController;
})();

window.rh.controller('ModernLayoutController', ModernLayoutController);
