const rh = require("../../../../lib/rh")
const _ = rh._;
const model = rh.model;
const search_utils = require('./utils');
const INDEX = require('./indices');
const SEARCH_CONSTS = require('./consts');
const Paths = require('../common/paths')


class Loader {
  constructor(path = ""){
    this._paths = new Paths(path)
  }

  init(callbackFn){
    this.loadIndex(callbackFn);
    this.loadMap(callbackFn);
  }

  _isIndexLoaded(){
    return this.index_data !== undefined;
  }

  getModelCount(){
    return this.index_data.Model.length;
  }
  getMapHash(hash_key){
    return this._hash_to_word[hash_key];
  }
  getHashes(input){
    if(input !== undefined && input.length > 0){
      return this.getAplhabetHashes(input[0]);
    }
  }
  getAplhabetHashes(alphabet){
    if(this.map_data && alphabet.length === 1){
      let lalphabet = alphabet.toLowerCase()
      return this.map_data[lalphabet];
    }
  }
  getWordModel(word, model_data){
    let word_model;
    let word_hash = this._getHashForWord(word);
    if(word_hash && model_data){
      word_model = model_data[word_hash[INDEX.MAP.MODEL_INDEX]];
    }
    return word_model;
  }

  getCount(word){
    let word_hash = this._getHashForWord(word);
    if(word_hash){
      return word_hash[INDEX.MAP.COUNT];
    }
  }
  getModelData(word_hash){ // async call if not loaded return empty array
    let fileno = word_hash[INDEX.MAP.MODEL_FILE_NO];
    let model_index = word_hash[INDEX.MAP.MODEL_INDEX];
    let data = model.get(this._paths.getModelKey(fileno));
    if(!data){
      this.loadModel(fileno);
    }
    return data && data[model_index] ;
  }
  _compareWordCount(hash_item1, hash_item2){
    let count1 = search_utils.getSafeElement(hash_item1, INDEX.MAP.COUNT);
    let count2 = search_utils.getSafeElement(hash_item2, INDEX.MAP.COUNT);
    if(count1 && count2) {
      return count2 - count1;
    }
    return 0;
  }
  _buildTopWords(words){
    words.sort(this._compareWordCount);
    words.splice(SEARCH_CONSTS.max_predictions *100)
    this._top_words = words;
  }
  get top_words(){
    return this._top_words;
  }
  getModelFileNo(word){
    let word_hash = this._getHashForWord(word);
    if(word_hash){
      return word_hash[INDEX.MAP.MODEL_FILE_NO];
    }
  }
  _getHashForWord(word){
    let hash;
    let word_key = this.getHashKey(word);
    if(word_key){
      hash = this._hash_to_word[word_key];
    }
    return hash;
  }
  getHashKey(word){
    if(word && word.length > 0){
      let lWord = word.toLowerCase();
      return this._word_hash[lWord];
    }
  }

  loadIndex(callbackFn) {
    if(this._isIndexLoaded() === false){
      _.loadScript(this._paths.getIndexFilePath(), true, () => {
        this.index_data = _.exports();
        this.onIndexLoaded(callbackFn);
      }, true);
    }
    else{
      this.onIndexLoaded(callbackFn);
    }
  }

  get nGram(){
    return this.index_data.nGram;
  }
  get search_model () {
    return this.index_data.Model;
  }
  get search_map(){
    return this.index_data.Map;
  }
  get total_count(){
    return this.index_data.totalCount;
  }
  get stop_words(){
    return this.index_data.stopWords;
  }

  loadModel(fileNo, callbackFn) {
    let data = [];
    if(fileNo!== undefined){
      let key = this._paths.getModelKey(fileNo)
      let data = model.get(key)
      if(data === undefined){
        model.publish(key, null)

        let filepath = this._paths.getModelFilePath(fileNo)
        _.loadScript(filepath, true, this.getOnModelLoadFn(fileNo, callbackFn), true);
      }
      else if(data === null && callbackFn !== undefined){

        model.subscribe(key, callbackFn);
      }
      else{
        this.doCallback(callbackFn, data, fileNo)
      }
    }
    else{
      this.doCallback(callbackFn, data, fileNo)
    }
  }
  doCallback(callbackFn, data, fileno){
    if(callbackFn) {
      callbackFn(data, fileno)
    }
  }
  getOnModelLoadFn(fileNo, callbackFn){
    return () => {
      let data = _.exports();
      model.publish(this._paths.getModelKey(fileNo), data);
      if(callbackFn){
        callbackFn(data, fileNo);
      }
    }
  }
  onMapLoaded(callbackFn){
    this.parseMap();
    if(this.index_data && callbackFn){
      callbackFn(this);
    }
  }

  onIndexLoaded(callbackFn){
    if(this.map_data && callbackFn){
      callbackFn(this);
    }
  }
  parseMap(){
    let data = this.map_data;
    this._word_hash = {};
    this._hash_to_word ={};
    let words = [];
    for (const letter in data){
      let words_data = data[letter];
      this._parseWordsData(words_data)
      words = words.concat(words_data);
    }
    this._buildTopWords(words);
  }
  _parseWordsData(words_data){
    _.each(words_data, (word_data) =>{
      let hash_key = parseInt(word_data[INDEX.MAP.HASH]);
      if(word_data[INDEX.MAP.WORD]) {
        let word = word_data[INDEX.MAP.WORD].toString();
        this._word_hash[word] = hash_key;
      }
      this._hash_to_word[hash_key] = word_data;
    });
  }
  isStopWord(word){
    let index = _.find(this.index_data.stopWords, (stop_word) =>{
      return search_utils.compareNoCase(word, stop_word) === 0
    });
    return index !== undefined;
  }

  getTotalCount(){
    return this.index_data.totalCount;
  }
  wordHashfromIndexKey(index_key, fileno){
    return _.find(this._hash_to_word, (map_item) =>{
      return map_item[INDEX.MAP.MODEL_INDEX] === index_key && map_item[INDEX.MAP.MODEL_FILE_NO] === fileno;
    });
  }
  loadMap(callbackFn){
    if(this.map_data === undefined){
      _.loadScript(this._paths.getMapFilePath(), true, () => {
        this.map_data = _.exports();
        this.onMapLoaded(callbackFn);
      }, true);
    }
    else{
      this.onMapLoaded(callbackFn);
    }
  }
}

module.exports = Loader;
