//https://tc39.es/proposal-object-values-entries/
//https://github.com/tc39/proposal-object-values-entries
if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    
    return resArray;
  };
}
