const SortedSet = require('./sorted_set');
const Parser = require('./input_parser');
const Prediction = require('./prediction');
const rh = require("../../../../lib/rh")
const _ = rh._;
const INDEX = require("./indices")


class TopicCounter{
  constructor(loader){
    this.newPredictions = [];
    this.predictionSet = new SortedSet();
    this.inputSet = new SortedSet();
    this._loader = loader;
  }
  computeCounts(predictions, parsed_input){
    this.newPrediction = [];
    this.parsed_input = parsed_input;
    _.each(predictions, (prediction) => {
      this.addUpdatedPrediction(prediction);
    });
    return this.newPredictions;
  }

  addUpdatedPrediction(prediction){
    let count = 1;
    if(this._loader !== undefined){
      this.createPredictionSet(prediction);
      count = this.computeTopicCount();
    }
    if(count > 0){
      let newPrdiction = new Prediction(prediction.term, prediction.probability, count, prediction.source);
      this.newPredictions.push(newPrdiction);
    }
  }
  computeTopicCount(){
    return this.predictionSet.set.length;
  }

  createPredictionSet(prediction){
    this.predictionSet = new SortedSet();
    let parsed_prediction = new Parser(prediction.term, true);
    _.each(parsed_prediction.words, (word, index) =>{
      this._addWordToSet(word, this.predictionSet, index);
    });
  }

  initInput(parsed_input){
    this.inputSet = new SortedSet();
    _.each(parsed_input.words, (word) =>{
      this._addWordToSet(word, this.inputSet);
    });
  }

  _addWordToSet(word, set){
    if(this._loader.isStopWord(word)) {
      return;
    }
    let wordHash = this._loader._getHashForWord(word);
    if(wordHash && wordHash.length > INDEX.MAP.TOPICS){
      set.intersectOrSet(wordHash[INDEX.MAP.TOPICS]);
    }
  }
}

module.exports = TopicCounter;
