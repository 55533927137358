const bind = (fn, me) => function(){ return fn.apply(me, arguments); };

let rh = require("../../src/lib/rh")
let nodeUtil = require('../../src/responsive_help/utils/node_utils')
let _ = rh._;
let $ = rh.$;
let model = rh.model;
let consts = rh.consts;
let KEY_TOC_DRILL_DOWN = consts('KEY_TOC_DRILL_DOWN');
let ANIM_TIME = 700;
let SyncToc;
let TocController;

SyncToc = ((() => {
  class SyncToc {
    sync(tc, info) {
      let node;
      tc.updateActiveBookInfo(0, '_');

      node = tc.widget.node;
      //tc.updateBreadcrumbInfo(tc.widget.node);

      this.reset(info);
      return this.syncToProjectToc(tc, node.children, ((_this => nodes => _this.syncToTocItem(tc, nodes)))(this));
    }

    reset(info) {
      let idx;
      this.parseTocInfo(info);
      idx = this.order.lastIndexOf('@');
      return this.parentOrder = idx !== -1 ? this.order.substring(0, idx) : '';
    }

    parseTocInfo(info) {
      let path;
      this.order = info.childOrder || '';
      path = _.splitAndTrim(info.childPrefix, '.');
      this.tocPath = _.reduce(path, (result, prefix) => {
        let arr, bookNo;
        arr = _.splitAndTrim(prefix, '@');
        if (bookNo = arr.shift()) {
          result.push({
            bookNo: _.parseInt(bookNo, 0),
            order: arr.length > 0 ? `@${arr.join('@')}` : ''
          });
        }
        return result;
      }, []);
      path = _.splitAndTrim(info.topicID || '0', '_');
      this.bookNos = _.map(path.shift().split('.'), item => _.parseInt(item, 0));
      return this.pageNo = _.parseInt(path.shift(), 0);
    }

    syncToProjectToc(tc, nodes, success) {
      let curBookNo, idx, path;
      if (this.tocPath.length > 0) {
        curBookNo = 0;
        path = this.tocPath.shift();
        idx = _.findIndex(nodes, child => {
          let childOrder;
          childOrder = $.dataset(child, 'childorder') || '';
          if (childOrder === path.order && tc.isBookNode(child)) {
            curBookNo++;
            if (curBookNo === path.bookNo) {
              return true;
            }
          }
          return false;
        });
        if (idx !== -1) {
          return tc.openBook(nodes[idx], true, ((_this => () => _.defer(() => {
            let child, childNodes;
            child = nodes[idx + 1];
            if (childNodes = child.children && child.children[0]) {
              return _this.syncToProjectToc(tc, childNodes.children, success);
            }
          })))(this));
        }
      } else if (success) {
        return success(nodes);
      }
    }

    syncToTocItem(tc, nodes, wait) {
      let curBookNo, curPageNo, lastBookNode;
      if (wait == null) {
        wait = 1;
      }
      curPageNo = 0;
      curBookNo = 0;
      lastBookNode = null;
      return _.any(nodes, ((_this => child => {
        let bookNode, childOrder, hasChild, pageNode, topicNode;
        childOrder = $.dataset(child, 'childorder') || '';
        if (childOrder !== _this.order) {
          return;
        }
        if (tc.isBookNode(child)) {
          bookNode = child;
          lastBookNode = bookNode;
        }
        if (bookNode) {
          curBookNo++;
        }
        if (_this.bookNos[0] !== curBookNo) {
          return;
        }
        if (tc.isPageNode(child)) {
          pageNode = child;
        }
        if (pageNode) {
          curPageNo++;
        }
        if (_this.bookNos.length === 1) {
          if (_this.pageNo === 0) {
            topicNode = child;
            if (lastBookNode) {
              tc.openBook(lastBookNode, true, () => tc.updateBookInfo());
            }
          } else if (pageNode && curPageNo === _this.pageNo) {
            topicNode = child;
          }
          tc.updateBookInfo();
          if (topicNode) {
            tc.selectLink(topicNode, true, wait);
          }
        } else if (lastBookNode && (hasChild = $.hasClass(child, 'child'))) {
          _this.bookNos.shift();
          tc.openBook(lastBookNode, true, () => _.defer(() => {
            let childNodes;
            if (childNodes = child.children && child.children[0]) {
              return _this.syncToTocItem(tc, childNodes.children, ANIM_TIME);
            }
          }));
          return true;
        }
        return topicNode != null;
      }))(this));
    }
  }

  return SyncToc;
}))();

TocController = ((() => {
  let ACTIVE_CLASS, COLLAPSING_CLASS, EXPANDED_CLASS, EXPANDING_CLASS, KEY_ACTIVE_BOOKID, KEY_ACTIVE_BOOK_LEVEL, KEY_BOOKID, KEY_BOOK_LEVEL, KEY_SHOW_CHILD, KEY_TOC, LOADING_BOOK, SELECTED_CLASS;

  EXPANDED_CLASS = 'expanded';

  ACTIVE_CLASS = 'active';

  COLLAPSING_CLASS = 'collapsing';

  EXPANDING_CLASS = 'expanding';

  LOADING_BOOK = 'loading-book';

  SELECTED_CLASS = 'selected';

  KEY_SHOW_CHILD = 'show_child';

  KEY_BOOKID = 'bookid';

  KEY_ACTIVE_BOOKID = 'active_bookid';

  KEY_BOOK_LEVEL = 'level';

  KEY_ACTIVE_BOOK_LEVEL = 'active_level';

  KEY_TOC = '.p.toc';
  let CURRENT_TOC_KEY = 'curtoc', TOC_ITEM_ATTR = 'data-tocitem';

  let KEY_BREADCRUMBS = consts('KEY_TOC_BREADCRUMBS');


  class TocController {
    constructor(widget) {
      this.widget = widget;
      this.subscribeTopicID = bind(this.subscribeTopicID, this);
      this.urls = []

      this.onClick = bind(this.onClick, this);
      this.updateBookInfo = bind(this.updateBookInfo, this);
      this._data = {};
      this.projectAbsRef = [];
      if (this.syncToc == null) {
        this.syncToc = new SyncToc;
      }
      this.widget.publish(KEY_BOOK_LEVEL, 0);
      this.widget.publish(KEY_BOOKID, '_');
      rh.model.subscribe(KEY_TOC, (data) => {
        this.widget.publish(CURRENT_TOC_KEY, data);
        this.widget.publish('curlevel', 0);
      });
      this.widget.subscribe(consts('KEY_TOC_SELECT_ITEM'), this.selectTocItem.bind(this));
      model.subscribe(consts('KEY_TOC_BACK_BUTTON_PRESSED'), this.onBackButtonClicked.bind(this));
      model.subscribeOnce([consts('EVT_PROJECT_LOADED'), consts('KEY_TOC_ORDER')], ((_this => () => {
        if (model.get(consts('KEY_PUBLISH_MODE'))) {
          return _this.getTOCData();
        } else {
          return _this.loadToc(KEY_TOC, '', () => {
            _this.widget.publish(consts('EVT_TOC_LOADED'), true);
            return _.defer(() => _this.widget.subscribe(rh.consts('KEY_TOPIC_ID'), _this.subscribeTopicID));
          });
        }
      }))(this));
    }

    expandBook(level, id, node, item) {
      this.selectTocItem({'id': id});
      let key = $.getAttribute(node, 'data-itemkey');
      rh.model.publish('EVT_TOC_LOADED'+'gototab',
        {'tab': 0, 'key': key, 'item': item});
    }

    updateBookInfo() {
      let id, newLevel;
      id = this.widget.get(KEY_ACTIVE_BOOKID);
      if ((id != null) && id !== this.widget.get(KEY_BOOKID)) {
        this.widget.publish(KEY_BOOKID, id);
      }
      newLevel = this.widget.get(KEY_ACTIVE_BOOK_LEVEL);
      if ((newLevel != null) && newLevel !== this.widget.get(KEY_BOOK_LEVEL)) {
        return this.widget.publish(KEY_BOOK_LEVEL, newLevel);
      }
    }

    updateActiveBookInfo(level, id) {
      this.widget.publish(KEY_ACTIVE_BOOKID, id);

      return this.widget.publish(KEY_ACTIVE_BOOK_LEVEL, level);
    }
    /*
    _addBookmark(node){
      let that = this.this;
      if(!Array.isArray(this.path) || this.path.length === 0){
        return false;
      }
      if(that.isTOCItem(node)){
        if(this.currIndex === this.path[0]){
          let index = this.path.shift();
          this.curr_path += (this.curr_path === "")? index.toString() : "_" + index.toString();
          let text = that.getText(node);
          this.bookmarks.push({text: text, path: this.curr_path });
          this.index = 0;
        }
        else{
        //traverseChild =false;
          this.currIndex += 1;
        }
      }
    }
    */
    getText(node){
      return node.textContent.trim();
    }
    _getPreviousItemNode(node){
      let pid = this.pid($.getAttribute(node, 'data-itemid'))
      return $.find(this.widget.node, '[data-itemid=\"' + pid +'\"]')[0]
    }

    updateBreadcrumbInfo(node){
      let $leafnode = node;
      let $node
      let breadcrumbs = []
      if(!this.isTOCItem(node)){
        return;
      }
      breadcrumbs.push(this._createBreadcrumbItem($leafnode))
      while($node = this._getPreviousItemNode($leafnode)){
        breadcrumbs.push(this._createBreadcrumbItem($node));
        $leafnode = $node
      }
      breadcrumbs.reverse();
      if(breadcrumbs.length > 0){
        breadcrumbs[breadcrumbs.length-1].lastNode = true;
      }
      breadcrumbs.curlevel = this.widget.get("curlevel");
      this.widget.publish(KEY_BREADCRUMBS, breadcrumbs);
    }
    _createBreadcrumbItem(node){
      let linkNode = node
      if(!this.hasTOCItemLink(node)){
        linkNode = this._findFirstLink(node)
      }
      let url = (linkNode && ($.getAttribute(linkNode,'href')
        || $.getAttribute(linkNode,'link')))
        || '#';
      return {id:$.getAttribute(node,'data-itemid'),
              text: this.getText(node),
              url: url,
              hasUrl: this.hasTOCItemLink(node),
              lastNode:false};
    }
    isTOCItem(node){

      return $.nodeName(node) === 'LI'
        || $.getAttribute(node, TOC_ITEM_ATTR)
    }

    _findFirstLink(bookNode){
      let context = {that:this};

      let node = nodeUtil.parentNode(bookNode);
      context.bookid = $.getAttribute(bookNode, 'data-itemid')
      $.traverseNode(node, this._findLinkFn, null, null, context);
      return context.linkNode
    }
    _isParentTocItem(node){
      return this.bookNode !== node && this.that.isTOCItem(node)
    }

    _findLinkFn(node){
      let id = $.getAttribute(node, 'data-itemid')
      if(id === this.bookid){
        this.bookFound = true;
      }
      if (this.bookFound && this.that.hasTOCItemLink(node) && this.linkNode === undefined){
        this.linkNode = node;
        return false;
      }
      return true;
    }
    nextLevel(node) {
      let level;
      level = _.parseInt($.dataset(node, 'itemlevel'), 0);
      if ($.hasClass(node, EXPANDED_CLASS)) {
        return level;
      } else {
        return level + 1;
      }
    }

    onClick(event) {
      let clickNode, hasLink, node, topNode;
      topNode = event.currentTarget;
      clickNode = event.target;
      hasLink = this.hasLink(clickNode);
      node = this.getItemNode(clickNode, topNode);
      if (this.isBookNode(node)) {
        if (!(hasLink && this.selectedNode !== node && this.isOpenBook(node))) {
          this.toggleBook(node, false);
          this.updateBreadcrumbInfo(node);
        }
      }

      if (!(!hasLink || this.isUrlNode(node) && this.hasExternalLink(clickNode))) {
        return this.selectLink(node, false);
      }
    }


    selectLink(node, scrollIntoView, wait) {
      if (wait == null) {
        wait = ANIM_TIME;
      }
      if (this.selectedNode) {
        $.removeClass(this.selectedNode, SELECTED_CLASS);
      }
      if (scrollIntoView && this.selectedNode !== node) {
        _.delay((() => node.scrollIntoView(true)), wait);
      }
      this.selectedNode = node;
      if (node) {
        this.updateBreadcrumbInfo(node);
        model.publish(consts('EVT_TOC_SELECTED_ITEM_CHANGED'))
        return $.addClass(node, SELECTED_CLASS);
      }
    }

    selectTocItem(tocObj){
      let id = tocObj.id + '_0'
      if(id){
        let childData = {}, level = -1;
        while(id){
          level++;
          childData[id] = true;
          id = id.substr(0, id.lastIndexOf('_'));
        }
        this.widget.publish("show_child", childData);
        this.widget.publish("curlevel", level);
        if(tocObj.url)
          rh.model.publish('EVT_TOC_LOADED'+'gototab',
              {'tab': 0, 'item': {'url': tocObj.url}});
      }
    }

    getItemNode(node, topNode) {
      return _.findParentNode(node, topNode, this.isTOCItem);
    }
    getChildItemNode(node, index) {
      return $.find(node,  node => $.nodeName(node) === 'LI'
        || $.getAttribute(node, TOC_ITEM_ATTR));
    }
    hasTOCItemLink(node){
      let href = $.getAttribute(node, 'data-haslink');
      return href === 'true';
    }
    hasLink(node) {
      let href;
      href = $.getAttribute(node, 'href');
      return href && href !== '#';
    }

    isBookNode(node) {
      return $.hasClass(node, 'book');
    }

    isTopicNode(node) {
      return $.hasClass(node, 'item');
    }

    isUrlNode(node) {
      return $.hasClass(node, 'url');
    }

    isPageNode(node) {
      return this.isTopicNode(node) || this.isUrlNode(node);
    }

    isOpenBook(node) {
      if (this.widget.get(KEY_TOC_DRILL_DOWN)) {
        return $.hasClass(node, ACTIVE_CLASS);
      } else {
        return $.hasClass(node, EXPANDED_CLASS);
      }
    }

    hasExternalLink(node) {
      let href;
      href = $.getAttribute(node, 'href');
      if (!(href && !_.isRelativeUrl(href))) {
        return false;
      }
      return !_.isUrlAllowdInIframe(href);
    }

    getBookNode(node, topNode) {
      let itemNode;
      itemNode = this.getItemNode(node, topNode);
      if (this.isBookNode(itemNode)) {
        return itemNode;
      } else {
        return null;
      }
    }

    animateCollapse(bookNode) {
      return _.each([bookNode, bookNode.nextElementSibling], node => {
        $.addClass(node, COLLAPSING_CLASS);
        return _.delay(() => $.removeClass(node, COLLAPSING_CLASS), ANIM_TIME);
      });
    }

    animateExpand(bookNode) {
      return _.each([bookNode, bookNode.nextElementSibling], node => {
        $.addClass(node, EXPANDING_CLASS);
        return _.delay(() => $.removeClass(node, EXPANDING_CLASS), ANIM_TIME);
      });
    }

    toggleBook(bookNode, scrollIntoView) {
      if (scrollIntoView == null) {
        scrollIntoView = true;
      }
      if (this.isOpenBook(bookNode)) {
        return this.closeBook(bookNode);
      } else {
        return this.openBook(bookNode, scrollIntoView);
      }
    }

    onBackButtonClicked(){
      let node = document.getElementsByClassName('expanded active') && document.getElementsByClassName('expanded active')[0]
      if(node){
        this.closeBook(node)
        _.defer(() => {
          this.widget.subscribe(KEY_ACTIVE_BOOKID, id => {
            let sendData
            if(id === '_'){
              sendData = false
            } else {
              sendData = true
            }
            model.publish(rh.consts('KEY_SHOW_TOC_BACK_BUTTON'), sendData)
          })
        })
      }
    }

    closeBook(node) {
      let id, keyShow;
      id = $.dataset(node, 'itemid');
      keyShow = `${KEY_SHOW_CHILD}${id}`;
      if (false !== this.widget.get(keyShow)) {
        this.widget.publish(keyShow, false);
      }
      this.updateActiveBookInfo(this.nextLevel(node), this.pid(id));
      this.updateBookInfo();
      if ($.hasClass(node, EXPANDED_CLASS)) {
        $.removeClass(node, EXPANDED_CLASS);
        return this.animateCollapse(node);
      }
    }

    openBook(node, scrollIntoView, success) {
      model.publish(rh.consts('KEY_SHOW_TOC_BACK_BUTTON'), true)
      let childOrder, id, key, keyShow;
      if (success == null) {
        success = this.updateBookInfo;
      }
      key = $.dataset(node, 'itemkey');
      id = $.dataset(node, 'itemid');
      childOrder = $.dataset(node, 'childorder');
      keyShow = `${KEY_SHOW_CHILD}${id}`;
      if (true !== this.widget.get(keyShow)) {
        this.widget.publish(keyShow, true);
      }
      this.updateActiveBookInfo(this.nextLevel(node), id);
      if (this.widget.get(key)) {
        if (!$.hasClass(node, EXPANDED_CLASS)) {
          $.addClass(node, EXPANDED_CLASS);
          this.animateExpand(node);
        }
        return success();
      } else {
        $.addClass(node, LOADING_BOOK);
        if (scrollIntoView) {
          node.scrollIntoView(false);
        }
        return this.loadToc(key, childOrder, ((_this => () => {
          $.removeClass(node, LOADING_BOOK);
          $.addClass(node, EXPANDED_CLASS);
          _this.animateExpand(node);
          return success();
        }))(this));
      }
    }

    extractTempData(event) {
      let tempItems;
      if(event && event.type === 'error') {
        tempItems = [];
        return tempItems;
      }
      tempItems = this.widget.get(rh.consts('KEY_TEMP_DATA'));
      this.widget.publish(rh.consts('KEY_TEMP_DATA'));
      return tempItems;
    }

    loadToc(fullKey, parentOrder, success) {
      let absRef, key, parentPath, ref1;
      ref1 = this.parseKey(fullKey), absRef = ref1.absRef, key = ref1.key;
      parentPath = absRef ? `${absRef}/` : '';
      return _.loadScript(`${parentPath}whxdata/${key}.new.js`, true, ((_this => (event) => {
        let tempItems;
        tempItems = _this.extractTempData(event) || [];
        _.each(tempItems, item => {
          item.absRef = absRef;
          if(item.type === 'remoteitem') {
            item.url = item.url && decodeURI(item.url);
          }
          if (parentOrder) {
            return item.childOrder = parentOrder;
          }
        });
        return _this.loadRefToc(tempItems, items => {
          _this.widget.publish(fullKey, items);
          if (success) {
            return success();
          }
        });
      }))(this));
    }

    loadRefToc(items, success) {
      let absRef, index, item;
      if (items == null) {
        items = [];
      }
      index = _.findIndex(items, item => {
        if (item.ref) {
          return true;
        }
      });
      if (index !== -1) {
        item = items[index];
        absRef = item.absRef || '';
        if (absRef) {
          absRef += '/';
        }
        absRef += item.ref;
        let prjOrder = model.get(consts('KEY_TOC_ORDER'))
        let childOrder = ''
        if (prjOrder[absRef]) { 
          childOrder = prjOrder[absRef].order
        }
        return _.loadScript(`${absRef}/whxdata/toc.new.js`, true, (event) => {
          let tempItems;
          tempItems = this.extractTempData(event) || [];
          _.each(tempItems, item => {
            item.absRef = absRef;
            if (childOrder) {
              return item.childOrder = childOrder;
            }
          });
          Array.prototype.splice.apply(items, [index, 1].concat(tempItems));
          return this.loadRefToc(items, success);
        });
      } else if (success) {
        return success(items);
      }
    }

    getTOCData() {
      return model.subscribeOnce(consts('KEY_PUBLISH_BASE_URL'), (function(_this) {
        return function() {
          var err;
          try {
            return model.subscribe(consts('KEY_PROJECT_LIST'), function(allProjects) {
              return model.subscribe(consts('KEY_MASTER_PROJECT_LIST'), function(list) {
                var items, len;
                len = list.length;
                items = [];
                return _.each(list, function(prj) {
                  prj = _.makeRelativeUrl(prj, allProjects[0]);
                  if (prj) {
                    prj = prj + "/";
                  }
                  return _.loadScript(prj + "whxdata/toc.new.js", true, (function(_this) {
                    return function(event) {
                      var tempItems;
                      tempItems = _this.extractTempData(event) || [];
                      _.each(tempItems, function (item) {
                        item.absRef = prj
                      });
                      return _this.loadRefToc(tempItems, function(resolvedItems) {
                        len--;
                        items = items.concat(resolvedItems);
                        if (len === 0) {
                          _this.widget.publish(KEY_TOC, items);
                          _this.widget.publish(consts('EVT_TOC_LOADED'), true);
                          return _this.widget.subscribe(rh.consts('KEY_TOPIC_ID'), _this.subscribeTopicID);
                        }
                      });
                    };
                  })(this));
                }, _this);
              });
            });
          } catch (error) {
            err = error;
            if (rh._debug) {
              return rh._d('warn', err.message);
            }
          }
        };
      })(this));
    }

    nextChildOrder(childOrder) {
      let orders;
      orders = childOrder.split('@');
      orders[orders.length - 1] = 1 + _.parseInt(orders[orders.length - 1], 0);
      return orders.join('@');
    }

    childOrder(parentOrder) {
      if (parentOrder) {
        return `${parentOrder}@1`;
      } else {
        return '@1';
      }
    }

    pid(id) {
      let path;
      path = id.split('_');
      path.pop();
      return path.join('_') || '_';
    }

    key(absRef, key) {
      let idx;
      if (absRef == null) {
        absRef = '';
      }
      idx = _.findIndex(this.projectAbsRef, ref => ref === absRef);
      if (idx === -1) {
        idx = this.projectAbsRef.length;
        this.projectAbsRef.push(absRef);
      }
      return `.p.child_toc.${idx}.${key}`;
    }

    parseKey(key) {
      let absRef, keys, lastKey;
      key = key.substring(3);
      keys = key.split('.');
      lastKey = keys.pop();
      absRef = this.projectAbsRef[keys.pop()] || '';
      return {
        key: lastKey,
        absRef
      };
    }

    url(item, id) {
      let bookMark, filePath, params, parentPath, url;
      if (!item.url) {
        return '#';
      }
      if (item.type === 'remoteitem') {
        return encodeURI(item.url);
      }
      parentPath = item.absRef ? _.ensureSlash(item.absRef) : '';
      url = `${parentPath}${item.url}`;
      bookMark = _.extractHashString(url);
      if (this.urls.indexOf(url) !== -1 || bookMark.length > 0) {
        bookMark = bookMark && `#${bookMark}`;
        filePath = _.filePath(url);
        params = _.extractParamString(url);
        if (params.length > 0) {
          params = `${params}&`;
        }
        params = `?${params}rhtocid=${id}`;
        url = `${filePath}${params}${bookMark || ''}`;
      }
      this.urls.push(url)
      return encodeURI(url);
    }

    tags(item) {
      let parentPath;
      parentPath = item.absRef ? `+${item.absRef}` : '';
      if (item['data-rhtags']) {
        return item['data-rhtags'] + parentPath;
      } else {
        return '';
      }
    }

    subscribeTopicID(tocInfo) {
      let path, tocid;
      if (tocInfo == null) {
        tocInfo = {};
      }
      tocid = _.hashParams()['rhtocid'];
      if (tocid) {
        path = this.getPathfromId(tocid);
        this.selectTocById(path, this.widget.node);
      } else {
        rh.model.subscribe(rh.consts('KEY_MASTER_PROJECT_LIST'), (list) => {
          if(list && list.length < 2)
            return this.syncToc.sync(this, tocInfo);
        })
      }
    }

    subscribeBookId(bookId) {
      // sukumar: TODO remove this line
      let books;
      let path;
      if (bookId) {
        path = this.getPathfromId(bookId);
        return this.selectTocById(path, this.widget.node, null, books);
      }
    }

    getPathfromId(id){
      let path;
      path = id.split('_');
      path.shift();
      path = _.map(path, item => _.parseInt(item, 0));
      return path;

    }
    selectTocById(path, node, wait, books) {
      let child, index;
      if (wait == null) {
        wait = 1;
      }
      if (!(node && node.children)) {
        return;
      }
      index = path.shift();
      child = _.find(node.children, function(child) {
        if (this.isBookNode(child) || this.isPageNode(child)) {
          if (index === 0) {
            return true;
          }
          index--;
        }
        return false;
      }, this);
      if (!child) {
        return;
      }

      if (path.length === 0) {
        this.selectLink(child, true, wait);
      }
      if (this.isBookNode(child)) {
        return this.openBook(child, true, ((_this => () => _.defer(() => {
          let childNode, parentNode;
          if (path.length === 0) {
            return _this.updateBookInfo();
          } else {
            childNode = child.nextElementSibling;
            parentNode = childNode.children && childNode.children[0];
            return _this.selectTocById(path, parentNode, ANIM_TIME);
          }
        })))(this));
      } else {
        return this.updateBookInfo();
      }
    }
  }

  TocController.prototype["class"] = item => {
    if (item.type === 'remoteitem') {
      return 'url';
    } else {
      return item.type;
    }
  };

  return TocController;
}))();

rh.controller('TocController', TocController);
