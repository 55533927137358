class CountingSeamaphore{
  constructor(callback){
    this._callback = callback;
    this._count = 0;
  }
  signal(){
    this._count++;
    if(this._count >= 0 && this._callback){
      this._callback();
    }
  }
  wait(count){
    let dec_count = count || 1;
    this._count-= dec_count;
  }
}

module.exports = CountingSeamaphore;