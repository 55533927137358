let rh = require("../../src/lib/rh")
let _ = rh._;
let $ = rh.$;
let model = rh.model;
let consts = rh.consts;

class TOCLevel {
  constructor() {
    this.data = {'' : {order: '', nextChild: 0}}
    model.subscribeOnce(consts('KEY_PROJECT_LIST'), allProjects => {
      _.each(allProjects, prj => {
        prj = _.makeRelativeUrl(this.trimFirst(prj, '.'), allProjects[0]);
        this.childOrder(prj)
      })
      model.publish(consts('KEY_TOC_ORDER'), this.data)
    })
  }

  trimFirst(path, char) {
    return path = (path.length && path[0] === char && path[1] !== char)? path.substring(1) : path
  }

  childOrder(absRef) {
    absRef = this.trimFirst(absRef, '/')
    if(!this.data[absRef]) {
      let path = absRef
      path =  path.substring(0, path.lastIndexOf('/'))
      path =  path.substring(0, path.lastIndexOf('/'))
      let parent = this.data[path]
      parent.nextChild++
      this.data[absRef] = {order: parent.order + '@' + parent.nextChild, nextChild: 0}
    }
    return this.data[absRef].order
  }
}

new TOCLevel