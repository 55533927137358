let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;
let { storage } = rh;
let urlFilter = rh._params.filter;

var ExpressionBuilder = (function() {
  let GRP_SEPARATOR = undefined;
  let TAG_SEPARATOR = undefined;
  let ELEMENT_SEPARATOR = undefined;
  ExpressionBuilder = class ExpressionBuilder {
    static initClass() {
    
      GRP_SEPARATOR = ':';
      TAG_SEPARATOR = ';';
      ELEMENT_SEPARATOR = ',';
    }
  
    constructor(config) {
      this.getUnchecked = this.getUnchecked.bind(this);
      this.handleTagStates = this.handleTagStates.bind(this);
      storage.init(consts('HELP_ID'));
    
      this.allTagNames = this.computeNames(rh.model.get(consts('KEY_MERGED_FILTER_KEY')));
    
      this.prepareTagStates();
    
      this.handleTagStates(rh.model.get(consts('KEY_PROJECT_TAG_STATES')));
    
      rh.model.subscribe(consts('KEY_PROJECT_TAG_STATES'),
        _.debounce(this.handleTagStates, 300),
        {initDone: true});
    }

    computeNames(tags) {
      return _.reduce(tags, function(result, tag) {
        result.push(tag.children ? this.computeNames(tag.children) : tag.name);
        return result;
      }
      , [], this);
    }

    getUnchecked(index, checked) {
      return _.filter(this.allTagNames[index], item => -1 === checked.indexOf(item));
    }

    computeExpression(states) {
      if (!states || !this.allTagNames) {
        return rh.model.publish(consts('KEY_TAG_EXPRESSION'), []);
      }

      let groupExprs = _.reduce(states, function(result, value, key) {
        if (_.isString(value)) {
          result.push({c: [value], u: []});
        } else if (value != null) {
          let validValues;
          if ((validValues = _.compact(value)) && (validValues.length > 0)) {
            result.push({
              c: validValues,
              u: this.getUnchecked(key, validValues)
            });
          }
        }
        return result;
      }
      , [], this);

      return rh.model.publish(consts('KEY_TAG_EXPRESSION'), groupExprs);
    }

    handleTagStates(states) {
      if (states == null) { states = {}; }
      this.computeExpression(states);
    
      // Persist new tag state in localDB or cookies
      return storage.persist('tag_states', states);
    }

    prepareTagStates() {
      return rh.model.subscribe(consts('KEY_PROJECT_FILTER_TYPE'), () => {
        let tagStates;
        if (urlFilter) {
          let filter = rh.model.get(consts('KEY_MERGED_FILTER_KEY'));
          tagStates = this.parseUrlFilter(filter, urlFilter.split(ELEMENT_SEPARATOR));
        }
        
        if (!tagStates) { tagStates = storage.fetch('tag_states'); }
      
        if (!tagStates) {
          tagStates = _.clone(rh.model.get(consts('KEY_DEFAULT_FILTER')));
        }

        if (tagStates) { return rh.model.publish(consts('KEY_PROJECT_TAG_STATES'), tagStates); }
      });
    }

    parseFilterElemnt(filter, element) {
      let temp = element.split(GRP_SEPARATOR);
      let name = temp[0];
      let elements = temp[1] && temp[1].split(TAG_SEPARATOR);
      let index = _.findIndex(filter, item => ((elements != null) === (item.children != null)) && (item.display === name));
      return {index, elements};
    }

    parseUrlFilter(filter, array) {
      if (!array && !array.length) { return; }
      let radioMode = rh.model.get(consts('KEY_PROJECT_FILTER_TYPE')) === 'radio';
      let firstString = true;
      return _.reduce(array, function(result, element, index) {
        let elements;
        ({index, elements} = this.parseFilterElemnt(filter, element));
        if (index !== -1) {
          let node = filter[index];
          if (node.children) {
            result[index] = this.parseUrlFilter(node.children, elements);
          } else if (!radioMode || firstString) {
            firstString = false;
            result[index] = node.name;
          }
        }
        return result;
      }
      , {}, this);
    }
  };
  ExpressionBuilder.initClass();
  return ExpressionBuilder;
})();
  
rh.widgets.ExpressionBuilder = ExpressionBuilder;