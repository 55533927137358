let { rh } = window;
let { _ } = rh;
let { model } = rh;
let { consts } = rh;

var Projects = (function () {
  let projectList = undefined;
  let projectsToBeLoaded = undefined;
  let projectQueue = undefined;
  let projDataFile = undefined;
  let REMOTENODE = undefined;
  let PROJNODE = undefined;
  let URL = undefined;
  let loaded = undefined;
  let loadProjectFile = undefined;
  let getProjectList = undefined;
  Projects = class Projects {
    static initClass() {
      projectList = [];
      projectsToBeLoaded = [];
      projectQueue = [];
      projDataFile = 'projectdata.js';
      REMOTENODE = 'remote';
      PROJNODE = 'project';
      URL = 'url';
      loaded = 0;

      loadProjectFile = function (curProjPath) {
        let curProjDataFile = curProjPath + '/' + projDataFile;
        return window.xmlJsReader.loadFile(curProjDataFile, function (xmlDoc, curProjPath) {
          let remoteNodes;
          let len = 0;
          loaded++;
          if (xmlDoc != null) {
            let projXmlNode = xmlDoc.getElementsByTagName(PROJNODE)[0];
            remoteNodes = projXmlNode.getElementsByTagName(REMOTENODE);
            len = remoteNodes.length;
          } else if ((curProjPath.length > 0)) {
            let index = projectsToBeLoaded.indexOf(curProjPath[0])
            if (index > -1) {
              projectsToBeLoaded.splice(index, 1)
            }
          }
          _.each(remoteNodes, function (remoteNode) {
            let path = curProjPath + '/' + remoteNode.getAttribute(URL);
            projectList.push(path);
            projectsToBeLoaded.push(path)
            return projectQueue.push(path);
          });
          getProjectList();
          if ((projectQueue.length === 0) && (loaded === projectList.length)) {
            model.publish(consts('KEY_PROJECT_LIST'), projectsToBeLoaded);
            return model.publish(consts('KEY_MASTER_PROJECT_LIST'), ["."]);
          }
        }
          , curProjPath);
      };

      getProjectList = () =>
        (() => {
          let result = [];
          while (projectQueue.length > 0) {
            let project = projectQueue.splice(0, 1);
            result.push(loadProjectFile(project));
          }
          return result;
        })()
        ;
    }
    constructor() {
      projectList.push('.');
      projectsToBeLoaded.push('.')
      projectQueue.push('.');
      getProjectList();
    }
  };
  Projects.initClass();
  return Projects;
})();

model.subscribe(consts('EVT_PROJECT_LOADED'), () =>
  _.defer(function () {
    if (!model.get(consts('KEY_PUBLISH_MODE')) && !window.gbPreviewMode) {
      return new Projects;
    }
  })
);
