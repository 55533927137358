const rh = require("../../lib/rh"),
  consts = rh.consts,
  model = rh.model,
  _ = rh._

model.subscribe(consts('KEY_CUSTOM_BUTTONS_CONFIG'), config => {
  let buttons = _.map(config, (item, index) => {
    return _.extend({class: `custom-button-${index}`}, item)
  })
  model.publish(consts('KEY_CUSTOM_BUTTONS'), buttons)
})
