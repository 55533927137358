let _exports = {
  minPreviousThreshold: 0.2,
  previousnGram: 1,
  max_predictions:5,
  PREDICTOR_SOURCE_ID:{
    HISTORY_PREDICTOR:0,
    NGRAM_PREDICTOR:1,
    GENERAL_PREDICTOR:2,
    CORRECTOR: 3
  }
};

module.exports = _exports;