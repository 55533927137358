let rh = require("../../lib/rh")
let $ = rh.$
module.exports = {

  nodeType: {
    ELEMENT_NODE: 1,
    ATTRIBUTE_NODE: 2,
    TEXT_NODE: 3,
    CDATA_SECTION_NODE: 4,
    ENTITY_REFERENCE_NODE: 5,
    ENTITY_NODE: 6,
    PROCESSING_INSTRUCTION_NODE: 7,
    COMMENT_NODE: 8,
    DOCUMENT_NODE: 9,
    DOCUMENT_TYPE_NODE: 10,
    DOCUMENT_FRAGMENT_NODE: 11,
    NOTATION_NODE: 12
  },

  removeChild(node, parent = this.parentNode(node)) {
    return parent && parent.removeChild && parent.removeChild(node)
  },
  appendChild(parent, newNode) {
    return parent && parent.appendChild && parent.appendChild(newNode)
  },
  parentNode(node) {
    return node && node.parentNode
  },
  childNodes(node) {
    return node && node.childNodes || []
  },
  toHtmlNode(html) {
    return this.childNodes($.createElement('div', html))
  },
  outerHTML(node) {
    return node && node.outerHTML || ''
  },
  insertAfter(node, newNode){
    return node.parentNode.insertBefore(newNode, node.nextSibling)
  },
  value(node) {
    return node && node.nodeValue
  },
  name(node) {
    return node && node.nodeName
  },
  type(node) {
    return node && node.nodeType
  },
  isElementNode(node) {
    return this.type(node) === this.nodeType.ELEMENT_NODE
  },
  isTextNode(node) {
    return this.type(node) === this.nodeType.TEXT_NODE
  }
}