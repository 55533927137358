const { rh } = window;
const { model } = rh;
const { _ } = rh;
const { consts } = rh;
const { http } = rh;
const { rhs } = rh;


var FilterReport = (function() {
  let getPostUrl = undefined;
  FilterReport = class FilterReport {
    static initClass() {


      getPostUrl = function() {
        const host = document.location.origin;
        const baseContext = model.get(consts('KEY_PUBLISH_BASE_URL')).substr(host.length);
        if (baseContext && !_.isEmptyString(baseContext)) {
          const hashString = _.mapToEncodedString(_.extend(_.addPathNameKey({area: rhs.area(),
          prj: rhs.project(), type: (rhs.type)(), agt: 'fltRpt', mgr: 'agm'
          })
          ));
          return `${baseContext}?${hashString}`;
        }
      };
    }

    constructor() {
      const timer = 3000;
      model.subscribeOnce(consts('EVT_PROJECT_LOADED'), () =>
        model.subscribeOnce(consts('KEY_PUBLISH_MODE'), function(val) {
          if (!val) { return; }
          var url = url || getPostUrl();
          let ckTags = [];
          const postToServer = _.debounce(() =>
            http.post(url, JSON.stringify(ckTags))
            .error(function(data, status) {
              if (rh._debug) { return rh._d('error', 'Filter report status ', status); }
            })

          , timer, false);

          return model.subscribe(consts('KEY_TAG_EXPRESSION'),  function(expr) {
            ckTags = [];
            const filterData = rh.model.get(consts('KEY_MERGED_FILTER_KEY'));
            for (let tags of Array.from(expr)) {
              for (let tag of Array.from(tags.c)) {
                var tagData;
                if (tagData = rh.filterObject.lookup(filterData, tag, false)) {
                  ckTags.push(tagData.display);
                }
              }
            }
            if (ckTags.length > 0) { return postToServer(); }
          });
        })
      );
    }
  };
  FilterReport.initClass();
  return FilterReport;
})();

new FilterReport;
