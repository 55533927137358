const rh = require("../../../../lib/rh")
const _ = rh._
module.exports = class Synonymns{
  constructor(synonymns) {

    this.synonymns = synonymns || []
    this.rootWords = {}
    this.merge()
  }
  merge() {
    this.rootWords = {}
    _.each(this.synonymns, (item) => {
      let synonymns = item.words
      let word = item.name
      _.each(synonymns, (syn) => {
        this._add(word, syn)
      })      
    })
  }
  _add(word, syn) {
    syn = syn.toLowerCase()
    word = word.toLowerCase()
    this.rootWords[syn] = this.rootWords[word] || word
  }

  getRoot(word) {
    return this.rootWords[word] || word
  }
}