let Prediction = require('./prediction');
let search_utils = require('./utils');
const INDEX = require('./indices');
let InputParser = require('./input_parser');
const search_consts = require('./consts');


class PredictonCreator{
  constructor({loader, model_item, total_count, parsed_input, continueFn}){
    this._model_item = model_item;
    this.parsed_input = parsed_input || new InputParser("");
    this._init();
    this._total_count = total_count;
    this._continueFn = continueFn || this._isStopWord;
    this._nextModelFn = this._nextModel;
    this._predictions = [];
    this._multi_prediction = false;
    this._loader = loader;
  }

  _init(){
    this.term = "";
    this.probability = 1;
    this._first_word = true;
    this.isFirstLevel = false;
    this._level = 0;
  }

  getPrediction(word_hash, isFirstLevel, previous, source){
    this.word_hash = word_hash;
    this.isFirstLevel = isFirstLevel;
    this.previous = previous;
    this.source = source;
    if(this.isFirstLevel){
      this._appendPreviousPredictions(this._model_item);
    }
    this._appendPrediction(this._model_item, word_hash);
    return this._createPrediction();
  }
  getMultiPredictions(word_hash, isFirstLevel, previous, source){
    this._multi_prediction = true;
    this.word_hash = word_hash;
    this.isFirstLevel = isFirstLevel;
    this.previous = previous;
    this.source = source;
    if(this.isFirstLevel){
      this._appendPreviousPredictions(this._model_item);
    }
    this._appendPrediction(this._model_item, word_hash);
    return this._predictions;
  }

  equalRemaingWord(model_item){
    let word = this._getword(model_item, this.word_hash);
    if(word){
      if(this.parsed_input.equalRemaingWord(word)) {
        return true;
      }
    }
    return false;
  }
  _appendPreviousPredictions(model_item){
    let previous_item = this._getPreviousItem(model_item);
    if(!previous_item) {
      return;
    }
    let previous_probaility = this._getPreviousProbability(previous_item);
    if(previous_probaility > search_consts.minPreviousThreshold){
      this._appendPreviousTerm(previous_item);
    }
  }
  _getPreviousItem(model_item){
    let previous_items = search_utils.getFirstLevelNextModel(model_item, true);
    if(previous_items){
      let previous_item = search_utils.getSafeElement(previous_items, 0);
      return previous_item;
    }
  }
  _appendPreviousTerm(model_item){
    let word = this._getword(model_item);
    if(word){
      if(this.parsed_input.equalLastWord(word)) {
        return;
      }
      this.term = this.parsed_input.replaceLastWord(word);
      this._first_word = false;
    }
  }
  _createPrediction(){
    if(this.term !== ""){
      let suggestion_text = this.parsed_input.completeSuggestion(this.term);
      return new Prediction(suggestion_text, this.probability, 1, this.source);
    }
  }

  _appendPrediction(model_item){
    this.probability = this.probability * this._calculateProbability(model_item);
    this._update_term(model_item);
    if(this._shouldCreatePrediction(model_item)){
      this._predictions.push(this._createPrediction())
    }
    if(this._continueFn(model_item)){
      let next_model = this._nextModel(model_item);
      this._total_count = this._getCount(model_item);
      this._level++;
      this._first_word = false;
      this.isFirstLevel = false;
      this.word_hash = undefined;
      if(next_model){
        this._appendPrediction(next_model);
      }
    }
  }
  _getPreviousProbability(previous_item){
    let total_count =  this._getCount(this.model_item);
    let count = search_utils.getCountfromModel(previous_item);
    return count/total_count;
  }
  _update_term(model_item){
    if(this.parsed_input.isPrevious){
      this.term = this._nextTerm(model_item) + this.term;
    }
    else{
      this.term = this.term + this._nextTerm(model_item);
    }
  }
  _getword(model_item, word_hash){
    word_hash = word_hash || this._get_word_hash(model_item);
    return search_utils.getSafeElement(word_hash, INDEX.MAP.WORD);
  }

  _get_word_hash(model_item){
    return this._loader.getMapHash(model_item[INDEX.MODEL.WORD_HASH]);
  }
  _nextModel(model_item){
    let next_model;
    if(this.isFirstLevel){
      next_model = search_utils.getFirstLevelNextModel(model_item, this.previous);
    }
    else{
      next_model = search_utils.getNextModel(model_item);
    }
    if(next_model && next_model.length >  0){
      return search_utils.getFirstPrediction(next_model);
    }
  }
  _nextTerm(model_item){
    let word = this._getword(model_item, this.word_hash);
    if(word){
      return (this._first_word) ? this.parsed_input.completeWord(word) : " " + word;
    }
    return "";
  }
  _calculateProbability(model_item){
    let count = this._getCount(model_item);
    return (count / this._total_count);
  }
  _getCount(model_item){
    if(this.word_hash){
      return search_utils.getCountfromWordHash(this.word_hash);
    }
    else {
      return search_utils.getCountfromModel(model_item);
    }
  }
  _shouldCreatePrediction(model_item){

    return this._multi_prediction &&
            !this._isStopWord(model_item) &&
            !this.equalRemaingWord(model_item);
  }
  _isStopWord(model_item){
    let word = this._getword(model_item, this.word_hash);
    if(!word){
      return false;
    }
    return this._loader.isStopWord(word);
  }
}
module.exports = PredictonCreator;
