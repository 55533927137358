let rh = require("../../../../lib/rh")
let _ = rh._;
const search_utils = require('./utils');

class HistoryReader{
  constructor(){
  }


  initHistory(list){
    if(this._list === undefined){
      this._list = list || [];
    }
  }
  getHistory(){
    return this._list || [];
  }

  _updateText(text, index){
    this.getHistory().splice(index, 1);
    this.getHistory().unshift(text);
  }

  _addNewText(newText){
    this.getHistory().push(newText);
  }

  add(newText){
    let nIndex = _.findIndex(this.getHistory(), (item) => {return search_utils.compareNoCase(newText.text, item.text) === 0;});
    if(nIndex >=0){
      this._updateText(newText, nIndex);
    }
    else{
      this._addNewText(newText);
    }
  }
  delete(searchText){
    let nIndex = _.findIndex(this.getHistory(), (item) => {return search_utils.compareNoCase(searchText, item.text) === 0;});
    if(nIndex >=0){
      this.getHistory().splice(nIndex);
    }
  }
}

module.exports = new HistoryReader();
