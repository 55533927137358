let rh = require("../../lib/rh")
let nodeUtils = require("../utils/node_utils")
let $ = rh.$
let _ = rh._;
class ModalDialog {

  createChildWidget() {
    this.node = $.createElement('div', this.template)
    nodeUtils.appendChild(document.body, this.node);
    this.widget = new rh.Widget({node: this.node})
    this.widget.init()
  }


  get template() {
    let initialStyle = this.initialStyle()

    return `
    <div class="rh-modal">
        <div class="rh-modal-container" ${initialStyle}>
          <div class="rh-modal-content" data-html="content"></div>
          <div class="rh-modal-close" data-click="@close(true)"> </div>
        </div>
    </div>`;
  }

  setOnImageLoad(){
    let modalContent = $.find(this.node, '.rh-modal-content')[0]
    if(!modalContent) {
      return
    }
    this.modalContent = modalContent
    let img = $.find(modalContent, 'img')[0]
    if(img){
      this.img = img
      img.addEventListener('load', this.setModalSize.bind(this))
      img.addEventListener('error', this.setModalSize.bind(this))
    }
  }
  setModalSize(){
    this.imageWidth = this.img.naturalWidth
    this.imageHeight = this.img.naturalHeight
    let position = this.calculatePosition(this.imageWidth, this.imageHeight)
    let modalContainer = $.find(this.node, '.rh-modal-container')[0]
    if(modalContainer){
      this.setContainerStyle(modalContainer, position)
    }
  }
  setContainerStyle(modalContainer, position){
    let top = Math.round(position.top)
    let left = Math.round(position.left)

    $.css(modalContainer, 'left', left.toString() + 'px')
    $.css(modalContainer, 'top', top.toString() + 'px')

    if(this.modalContent) {
      this.setImageSize(position.width, position.height)
    }
    $.css(modalContainer, 'display', "block")

  }
  calculateImageSize(size){
    let aspect = size.imageWidth/size.imageHeight
    if(size.imageWidth < size.pageWidth && size.imageHeight < size.pageHeight){
      return {width: size.imageWidth, height: size.imageHeight}
    }

    if(size.imageWidth >= size.pageWidth && size.imageHeight <= size.pageHeight){
      return {height: size.pageWidth/aspect, width: size.pageWidth}
    }
    if(size.imageWidth <= size.pageWidth && size.imageHeight >= size.pageHeight){
      return {height: size.pageHeight, width: size.pageHeight*aspect
      }
    }
    if(size.imageWidth >= size.pageWidth && size.imageHeight >= size.pageHeight){
      return this._calculateFullyExceedSize(size, aspect)
    }
  }
  _calculateFullyExceedSize(size, aspect){
    let pageAspect = size.pageWidth/size.pageHeight
    if(aspect < pageAspect){
      return {height: size.pageHeight, width: size.pageHeight*aspect}
    }
    else{
      return{ height: size.pageWidth/aspect, width: size.pageWidth}
    }
  }



  calculatePosition(imageWidth, imageHeight){
    let pageWidth = window.innerWidth || document.body.clientWidth
    let pageHeight = window.innerHeight || document.body.clientHeight
    pageWidth -= 70
    pageHeight -= 70
    pageWidth = (pageWidth >= 0)?  pageWidth: 0
    pageHeight = (pageHeight >= 0)?  pageHeight: 0

    let size = {imageWidth:imageWidth, imageHeight:imageHeight, pageWidth:pageWidth, pageHeight:pageHeight}
    let image_size = this.calculateImageSize(size)
    imageWidth = image_size.width
    imageHeight = image_size.height
    let left = Math.max(5, (pageWidth - imageWidth)/2 )
    let top = Math.max(5, (pageHeight - imageHeight)/2 )

    return {
      left: left,
      top: top,
      width: imageWidth,
      height: imageHeight,
      pageWidth: pageWidth,
      pageHeight: pageHeight
    }
  }
  initialStyle(){
    return (this.isImage)? `style="display:none"` : ''
  }

  ShowModal(config) {
    this.isImage = config.isImage
    this.createChildWidget()
    this.widget.subscribe('close', this._close.bind(this))
    this.widget.publish('content', config.content)
    _.defer(this.setOnImageLoad.bind(this))

  }

  setImageSize(maxWidth, maxHeight){
    if(this.imageWidth > maxWidth){
      $.css(this.img, 'width', `${Math.round(maxWidth)}px`)
    }
    if(this.imageHeight > maxHeight){
      $.css(this.img, 'height', `${Math.round(maxHeight)}px`)
    }
  }
  _close(){
    this.widget.destruct();
    this.widget = undefined;
    nodeUtils.removeChild(this.node);
    this.node = undefined;
  }
}


if (!_.isIframe()) {
  rh.model.csubscribe('SHOW_MODAL', config => {
    let model = new ModalDialog()
    model.ShowModal(config)
  })
}

rh.model.csubscribe('SHOW_MODAL_IN_TOPIC', config => {
  let model = new ModalDialog()
  model.ShowModal(config)
})
