/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2018 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
const rh = require("../../../../lib/rh")
const _ = rh._
const consts = rh.consts

module.exports = class SearchLanguage {

  constructor(code, loader, cb) {
    this.cb = cb 
    this.pipeLineFn = {}
    this.code = this._extractCode(code)
    this.useLang = this._useLang()
    this.loader = loader 
  }
  setLanguage(lunr) {
    if (this.useLang) {
      _.loadScript(this.loader.getUrl(consts('SEARCH_STEMMER_SUPPORT')), true, () =>{
        let stemmer = _.exports()
        stemmer(lunr)
        if(this.code === 'jp'){
          _.loadScript(this.loader.getUrl(consts('SEARCH_TINY_SEGMENTER')), true, () =>{
            let segmenter = _.exports()
            segmenter (lunr)
            _.loadScript(this.loader.getUrl(consts('SEARCH_LNG_SUPPORT')), true, () =>{
              let lngHandler = _.exports()
              lngHandler(lunr)
              return this.cb && this.cb(lunr)
            })
          })
        }
        else{
          _.loadScript(this.loader.getUrl(consts('SEARCH_LNG_SUPPORT')), true, () =>{
            let lngHandler = _.exports()
            lngHandler(lunr)
            return this.cb && this.cb(lunr)
          })
        }
      }) 
    }
    else{
      this.setTokenizer(lunr)
      return this.cb && this.cb(lunr)
    }
    //this.initFns(lunr)
  }
  replaceFn(db, lunr){
    if(this.useLang){
      db.pipeline.before(lunr.stemmer, lunr[this.code].stemmer)
      db.pipeline.remove(lunr.stemmer)
      if(lunr[this.code] && lunr[this.code].tokenizer){
        lunr.tokenizer =  lunr[this.code].tokenizer 
      }
    }
  }
  setTokenizer(lunr) {
    lunr.tokenizer.separator = /[\s\.\-\'\,\n;\:\\\/\"]+/
  }
  initFns(lunr) {
    let lunrLocalized = this.useLang? lunr[this.code] : lunr
    let functions = this.useLang? this.fn[this.code] || this.fn.en : this.fn.en
    _.each(functions, (fnName, fieldName) => { 
      if(this.code !== 'jp') {
        lunr.Pipeline.registerFunction(lunrLocalized[fnName], fieldName)
      }
      this.pipeLineFn[fieldName] = lunrLocalized[fnName]
    }) 
  }
  
  get stemmer() {
    return this.pipeLineFn.stemmer
  }
  
  get stopWordFilter(){
    return this.pipeLineFn.stopWordFilter 
  }

  get tokenizer() {
    return this.pipeLineFn.tokenizer
  }
  initIndexing(node, lunr) {
    if (this.useLang) {
      node.use(lunr[this.code])
    }
  }
  _useLang() {
    return this.code && (['en'].indexOf(this.code) === -1 )
    && this.codes.indexOf(this.code) !== -1
  }
  _extractCode(code) {
    if (code) {
      let id = code.indexOf('_')
      let lngCode = code.substring(0, id).toLowerCase()
      if(lngCode === 'ja'){
        lngCode = 'jp'
      }
      return lngCode
    }
    
  }

  get codes() {
    return [
      "da",
      "de",
      "du",
      "es",
      "fi",
      "fr",
      "hu",
      "it",
      "ja",
      "jp",
      "no",
      "pt",
      "ro",
      "ru",
      "sv",
      //"th",
      "tr"
    ]
  }
  get fn(){
    return {
      "da": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "de": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "du": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "en": {
        tokenizer: "tokenizer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "es": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "fi": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "fr": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "it": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "ja": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "jp": {
        tokenizer: "tokenizer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "no": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "pt": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "ro": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "ru": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "sv": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "th": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      },
      "tr": {
        tokenizer: "trimmer", stopWordFilter: "stopWordFilter", stemmer: "stemmer"
      }
    }
  }
}