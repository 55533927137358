let { rh } = window;
let { model } = rh;
let { _ } = rh;
let { consts } = rh;
let { http } = rh;


var GlossaryController = (function() {
  let entrys = undefined;
  GlossaryController = class GlossaryController extends rh.NavController {
    static initClass() {
  
      entrys = 'entrys';
    }
    constructor(widget) {
      super();
      this.data = [];
      this.chunkCount = 0;
      this.count = 0;
      this.keys = '';
      this.alhpabet = '';
      this.loadNavData('glo');
      this.filter = '';
      this.widget = widget;
      this.widget.publish('show', {});
    }

    addElement(elements, list) {
      return (() => {
        let result = [];
        _.each(elements[entrys], (element) => {
          if (element != null) {
            if (!this.lookup(list, element)) {
              result.push(list.push(element));
            } else {
              result.push(undefined);
            }
          }
        }, this);
        return result;
      })();
    }

    getEscapedString(str) {
      return str.replace(/\\"/g, '"').replace(/\\\\/g, '\\')
    }

    exists(name) {
      let ch = this.alphaText(name);
      if (this.alhpabet.indexOf(ch) > -1) { return true; }
      this.alhpabet += ch;
      return false;
    }

    isFiltered(name, modelKey) {
      let filter = modelKey ? model.get(modelKey) : this.filter;
      return filter &&
       (name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) === -1);
    }

    alphaText(name) {
      return name.toUpperCase().charAt(0);
    }

    filterGlo(value) {
      this.filter = value.toLocaleLowerCase();
      this.alhpabet = '';
      return model.publish(consts('PROJECT_GLOSSARY_DATA'),
       model.get(consts('PROJECT_GLOSSARY_DATA')));
    }
  };
  GlossaryController.initClass();
  return GlossaryController;
})();



rh.controller('GlossaryController', GlossaryController);