const rh = require("../../../../../lib/rh")
const _ = rh._
const CbtFilter = require("./cbt")
const lunr = require("lunr")
module.exports = class SearchQuery {
  constructor(text, settings, opts) {
    this.originalText = text.trim()
    this.text = this.originalText.toLowerCase()
    this.settings = settings || {}
    this.opts = opts || {}
    this.opts.gbHighLight = global.gbHighLight
    this.checkExactMatch()
  }
  filter(itemList) {
    let filter = new CbtFilter(this.settings.fields, this.opts.origin, this.opts.cbt)
    return filter.filter(itemList)
  }
  getParams(isStopWordFn) {
    this.isStopWord = isStopWordFn
    return `${this.fieldsParam}`
  }

  isEqual(other) {
    if (this.text !== other.text || this._bExactMatch !== other._bExactMatch) {
      return false
    }
    return _.isEqual(this.opts, other.opts)
  }
  getFuzzyLength(token)
  {
    return _.max([0, Math.floor(Math.log2(token.length) - 1)])
  }
  getQueryText(id, token, boost) {
    if (this.isStopWord(token)) {
      return ''
    }
    else if (this.opts.fuzzy) {
      return `${id}:${token}~${this.getFuzzyLength(token)}^${boost}`
    }
    else if (this.opts.searchSubstrings && !this._bExactMatch)
    {
      return `${id}:${token}^${boost} ${id}:*${token}*^${Math.round(Math.sqrt(boost))}`
    }
    else {
      return `${id}:${token}^${boost}`
    }
  }

  checkExactMatch() {
    if (this._isExactMatch) {
      this._bExactMatch = true
      this.text = this.text.substring(1, this.text.length - 1)
    }
  }

  get _isExactMatch() {
    return (this.text.length > 2)
      && this.quotesChar.indexOf(this.text[0]) !== -1
      && this.quotesChar.indexOf(this.text[this.text.length - 1]) !== -1
  }

  get exactMatch() {
    return this._bExactMatch
  }

  get exaxctTerms() {
    return [this.text]
  }

  get quotesChar() {
    return ["\"", "\'"]
  }
  getFieldBoost(field) {
    return _.reduce(field, (max, rule) => {
      return (rule && rule.boost && rule.boost > max) ? rule.boost : max
    }, 1)
  }

  get fieldsParam() {
    let fieldquery = ''
    _.each(this.settings.fields, (item, id) => {
      if (!this.filter(item)) {
        let tokens = _.filter(_.map(lunr.tokenizer(this.text), token => token.str), str => !_.isEmptyString(str.trim()) )
 
        let boost = this.getFieldBoost(item)
        _.each(tokens, token => {
          fieldquery = `${fieldquery} ${this.getQueryText(id, token, boost)}`
        })
      }
    })
    return fieldquery
  }
}
