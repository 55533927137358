const SearchDb = require ('./db')
const Query = require('./query')
const Summary = require('./summary')
const rh = require("../../../../../lib/rh")
const _ = rh._
const Seamaphore = require('../../../../common/counting_seamaphore')
const TextMerger = require('../text_merger')
const SearchMetadata = require('../metadata')
const CbtFilter = require("./cbt")
module.exports = class SearchProcessor{
  constructor(loader){
    this.loader = loader
    this.metadataLoaded = this.dbLoaded = false;
    this.loader.loadDB((dbContent)=>{
      this.initDB(dbContent)
    })
    this.loader.loadMetaData((metadata)=>{
      this.initMetadata(metadata)
    })
  }

  getPath(){
    return this.loader.getPath()
  }
  
  initMetadata(metadata) {
    this.metadataLoaded = true 
    this.metadata = new SearchMetadata(metadata)
    
    if (!this.init && this.dbContent) {
      this.initDB(this.dbContent)
      this.dbContent = null
    }
    rh.model.publish(rh.consts('KEY_SHOW_CONTEXT'), this.contextLimit > 0)

  }

  get searchSubstrings() {
    return _.get(this.metadata.settings, 'searchsubstrings')
  }

  get contextLimit()
  {
    let num = parseInt(this.metadata.contextLimit)
    if (Number.isNaN(num))
    { 
      return 100
    }
    return num
    
  }

  initDB(dbContent) {
    if(this.metadataLoaded){
      this.db = new SearchDb(dbContent, this.loader, this.metadata.settings)
      this.init = true
      if (this.waiting) {
        this.waiting = false
        this.getResults()
      }
    }
    else {
      this.dbContent = dbContent
    }
  }
  topicsLoaded() {
    if (this.exactSearch) {
      this.filter()
    }
  }
  
  isNewQuery(text, opts){
    if(!this.init){
      return true
    }
    if(!this.metadata.settings){
      return false
    }
    let query = this.buildQuery(text, opts)
    return !this.query || !this.query.isEqual(query)
  }
    
  search(text, opts, callback, resultsCallback) {
    if(this.isNewQuery(text, opts)) {
      this.text = text
      this.seamaphore = new Seamaphore(this.topicsLoaded.bind(this))
      this.topicCallback = callback
      this.opts = opts
      this.summaries = {}
      this.resultsCallback = resultsCallback
      this.getResults()
    }
  }

  filter() {
    let terms = this.query.exaxctTerms
    this.results = _.filter(this.results, (result) => {
      let topicText = this.resultsMetadata[result.id].topicText
      return this.matchesExact(terms, topicText, result.id)
    })
    this.resultsCallback(this.query.text, this.results, this.glossaryResult)
    this.processSummary()
  }

  processSummary(){
    _.each(this.results, result => {
      let id = result.id
      this.topicCallback(this.loader.getId(id), this.summaries[id], this.createUrl(id, this.query.exaxctTerms)) 
    })
  }

  matchesExact(terms, text, id) {
    let found = false
    _.each(terms, term => {
      let idx = text.toLowerCase().indexOf(term)
      if (idx !== -1) {
        this.addSummary(id, text, idx, term)
        found = true
      }
    })
    return found
  }
  
  addSummary(id, text, idx, term) {
    let summary =  this.metadata.getTopicSummary(id)
    if (this.metadata.context && summary) {
      this.summaries[id] = summary
    }
    else {
      let summaryExtractor = new Summary(this.metadata.topicData, this.metadata.context, this.metadata.contextLimit)      
      this.summaries[id] = summaryExtractor.getSummaryText(text, idx, term.length, term)
    }
  }
  
  getResults() {
    if (!this.init) {
      this.waiting = true
      return
    }
    this.query = this.buildQuery(this.text, _.extend(this.opts, { searchSubstrings: this.searchSubstrings }))
    this.exactSearch = this.query.exactMatch
    this.glossaryResult = this.searchGlossary(this.text)
    this.results = this.process()
    if (this.needsCorrection) {
      this.doCorrection()    
    }

    if (!this.exactSearch || this.results.length === 0) {
      this.resultsCallback(this.query.text, this.results, this.glossaryResult)
    }
  }
  
  get needsCorrection () {
    return this.results.length === 0
      &&  this.metadata.settings.fuzzy
      && !this.query.exactMatch
  }

  doCorrection(){
    this.query = this.buildQuery(this.query.text, _.extend(this.opts, { fuzzy: true }))
    this.results = this.process()
  }
  
  searchGlossary() {
    return this.processGlossary()
  }

  buildQuery(text, opts){
    return new Query(text,  this.metadata.settings, opts, this.filterItem.bind(this))
  }
  
  process() {
    this.resultsMetadata = {}
    let searchResults = this.db.search(this.query)
    this.seamaphore.wait(searchResults.length)
    _.each((searchResults), (result) => {
      let topicData = this.metadata.getTopicData(result.ref)
      this.resultsMetadata[result.ref] = result.matchData.metadata
      this.loader.loadTextData(this.query, result.ref, this.processTopicData.bind(this))
      _.extend(result, topicData)
      result.id = result.ref
      result.ref = this.loader.getId(result.ref)
    })
    return searchResults
  }
  processGlossary() {
    return this.db.searchGlossary(this.text)
  }

  processTopicData(query, id, data) {
    if (!this.query.isEqual(query)) {
      return
    }
    let matchResult = this.resultsMetadata[id]
    if (matchResult) {
      let wordlist = []
      let summaryExtractor = new Summary(this.metadata.topicData, this.metadata.context, this.metadata.contextLimit)
      let summary = summaryExtractor.getSummary(id, matchResult, wordlist, data)
      summary = summary.replace(/\t+/gi, ' ').trim()
      this.resultsMetadata[id].topicText = this.createText(id, data)
      if(!this.exactSearch) {
        this.topicCallback(this.getId(id), summary, this.createUrl(id, wordlist))
      }
    }
    this.seamaphore.signal()
  }

  createText(topicId, texts) {
    let nextTexts = this.metadata.getTopicNextId(topicId)
    let merger = new TextMerger(nextTexts, texts, this.filterId.bind(this))
    return merger.getText()
  }

  filterId(id){
    let fields = this.metadata.fields
    let fieldItem = fields[id]
    return this.filterItem(fieldItem)  
  }
  
  filterItem(fieldItem) {
    let fields = this.metadata.fields
    let filter = new CbtFilter(fields, this.opts.origin, this.opts.cbt)
    return fieldItem && filter.filter(fieldItem)
  }
  
  getId(id) {
    return this.loader.getId(id)
  }

  createUrl(id, wordlist) {
    let data = this.metadata.getTopicData(id)
    let urlParam = {
      rhsearch: this.query.originalText
    }
    if(global.gbHighLight){
      let hlTerm = this.exactSearch ? `"${wordlist[0]}"` : (wordlist||[]).join(' ')
      urlParam.rhhlterm = hlTerm
    }
    if (data) {
      let resultsParams = '?' + _.mapToEncodedString(urlParam)
      return `${this.loader.getUrl(data.relUrl)}${resultsParams}`
    }
  }

}
