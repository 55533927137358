const search_utils = require('./utils');
const rh = require("../../../../../src/lib/rh")
const _ = rh._;

class InputParser{
  constructor(text, isWholeWord, selection, isPrevious){
    this.isPrevious = isPrevious;
    this.selection = (selection === undefined) ? text.length : selection
    let separator = this._calculateSeparator(text);
    if(!this.isPrevious){
      this.isWholeWord = isWholeWord || this._isWholeWord(text, separator);
    }
    this.initText(text, separator);
  }

  initText(text, separator){
    this._computeText(text, separator);
    this.trimmedText = this.original_text.trim();
    this.words = _.compact(this.trimmedText.split(" ") || []);
    this._reverseIfPrevious();
    if(!this.isWholeWord && this.words.length > 0){
      this._partial = (this.words.splice(-1))[0];
    }
  }
  _computeText(text, separator){
    this._right_text = "";
    this._left_text = "";

    if(separator === undefined){
      separator = text.length;
    }
    this.isSplitText = !(separator === 0 || separator === text.length)
    let left_text = text.substring(0, separator);
    let right_text = text.substring(separator);

    if(this.isPrevious){
      this.original_text = right_text;
      this._left_text = left_text.trim();
    }
    else{
      this.original_text = left_text;
      this._right_text = right_text.trim();
    }
  }

  equalRemaingWord(word){
    if(this._left_text !== "" || this._right_text !==""){
      let remainingWord = this._getRemainigWord();
      if(remainingWord){
        return search_utils.compareNoCase(remainingWord, word) === 0;
      }
    }
    return false
  }


  _getRemainigWord(){
    let words = (this.isPrevious)? this._left_text.split(" ")
                                    : this._right_text.split(" ");

    words = words || []
    words = _.compact(words);
    if(words.length > 0){
      return (this.isPrevious)? words[words.length -1] : words[0];
    }
  }

  completeSuggestion(text){
    if(this.isPrevious){
      return (this._left_text.length === 0)? text : this._left_text + " " + text;
    }
    else{
      return (this._right_text.length === 0)? text : text + " " + this._right_text;
    }
  }
  _calculateSeparator(text){

    if(this.isPrevious){
      if(this.selection === 0){
        this.isWholeWord = true;
        return 0;
      }
      if((text.length > this.selection) && text[this.selection-1] !== " "){
        let previousSpaceIndex = text.lastIndexOf(" ", this.selection-1);
        this.isWholeWord = false;
        return (previousSpaceIndex >= 0) ? previousSpaceIndex+1 : 0;
      }
      this.isWholeWord = true;
    }
    return this.selection;
  }
  _reverseIfPrevious(){
    if(this.isPrevious){
      this.words.reverse();
    }
  }
  _isWholeWord(text, separator){
    if(this.isPrevious){
      if (separator === 0) {
        return true;
      }
      return (text.length > separator) && text[separator-1] === " ";
    }

    return separator > 0 && text[separator -1] === " ";
  }
  compare(other_input, start = 0, end = undefined){
    end = (end === undefined) ?  this.words.length -1 : end;
    if(this.words.length > other_input.words.length){
      return false;
    }
    let i = start;
    for(; i <= end; i++){
      let word1 = this.words[i];
      let word2 = other_input.words[i];
      if(search_utils.compareNoCase(word1, word2) !== 0) {
        return false;
      }
    }
    return this.matchPartial(other_input, i);
  }
  matchPartial(other_input, index){
    if(this._partial === undefined){
      return true;
    }
    if(other_input.words.length <= index){
      return false;
    }
    let other_word = other_input.words[index];
    return this.comparePartial(other_word);
  }
  comparePartial(word){
    this._matchExp = new RegExp('^' + this._partial, 'i');
    return this._matchExp.test(word);
  }
  completeWord(next_word){
    if(this.isWholeWord || this._partial === undefined || this._partial.length === 0){
      return this.getTermForCompleteWord(next_word, this.trimmedText);
    }
    else{
      return this.getTermForPartialText(next_word);
    }
  }

  getTermForPartialText(next_word){
    let partial_next_word = next_word.substring(this._partial.length, next_word.length);
    if(this.isPrevious){
      let trimmedText = "";
      let firstSpaceIndex = this.trimmedText.indexOf(' ');
      if(firstSpaceIndex >= 0){
        trimmedText = this.trimmedText.substring(firstSpaceIndex);
      }
      return this._partial + partial_next_word + trimmedText;
    }
    else{
      return this.trimmedText + partial_next_word;
    }
  }

  getTermForCompleteWord(next_word){
    if(this.isPrevious){
      return next_word + " " + this.trimmedText;
    }
    else{
      return this.trimmedText + " " + next_word;
    }
  }

  replaceLastWord(next_word){
    let text = this.trimmedText;
    if(this._partial !== undefined && !this.isWholeWord){
      let index = this.trimmedText.length - this._partial.length;
      text = this.trimmedText.substring(0, index);
      text = text.trim();
    }
    return text + " " + next_word;
  }
  equalLastWord(word){
    if(this.words.length === 0) {
      return false;
    }
    let last_word = this.words[this.words.length -1];
    return search_utils.compareNoCase(last_word, word) === 0;
  }
}

module.exports = InputParser;
