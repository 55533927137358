let rh = require("../../src/lib/rh")
let _ = rh._;

_.findEditDist = (first, second) =>{
    if(first == second)
      return 0;
    let prevDist=[];
    let dist=[];
    for(var i=0;i<second.length+1;i++)
    {
      prevDist.push(i);
      dist.push(0);       //just to create a array of size second.length+1
    }

  for(var i=0;i<first.length;i++)
  {
    dist[0]=i+1;

    for (var j = 0; j < second.length; j++)
    {
        let curr;
        if(first[i]==second[j])
          curr=0;
        else
          curr=1;

        dist[j + 1] = Math.min(dist[j] + 1, prevDist[j + 1] + 1, prevDist[j] + curr);
    }

    for (var j = 0; j < prevDist.length; j++)         
      prevDist[j] = dist[j];
  }   
  return  dist[dist.length-1]
};